import React, { createContext, useContext, useState, ReactNode } from "react";

interface GlobalStateContextProps {
  lpName: any;
  setlpName: (value: string) => void;
}

const GlobalStateContext = createContext<GlobalStateContextProps | undefined>(
  undefined
);

export const GlobalStateProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [lpName, setlpName] = useState<string>(() => {
    if (typeof window !== "undefined") {
      return (
        localStorage.getItem("selectedOption") ||
        process.env.REACT_APP_LP_NAME ||
        "TC2009"
      );
    }
    return process.env.REACT_APP_LP_NAME || "TC2009";
  });

  return (
    <GlobalStateContext.Provider value={{ lpName, setlpName }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = (): GlobalStateContextProps => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }
  return context;
};
