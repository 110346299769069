import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TextField,
  Typography,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Button,
  TableHead,
} from "@mui/material";
import axiosInstance from "../../utils/axios/axios.instance";
import { IPortfolioStatic } from "../../interfaces/portfolioStatic.interface";
import PortfolioSelectionComponent from "../portfolioSelection";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import { IFundStatic } from "../../interfaces/fundStatic.interface";
import { formatDateToQuarter } from "../../utils/dateUtil";
import { PortfolioHistoricalData } from "../../interfaces/portfolioQtr.interface";
import { useGlobalState } from "../../contexts/GlobalStateContext";
import { useSearchParams } from "react-router-dom";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);
interface RowData {
  [key: string]: string | number; // Assuming all values are either string or number for simplicity
}

interface TableData {
  [key: string]: any;
}
interface FundTableProps {
  data: TableData[];
}
interface PortfolioData {
  [key: string]: any;
}
interface TableHeadingProps {
  children: React.ReactNode;
  colSpan?: number;
  isFirstColumn?: boolean;
  isFirstRow?: boolean;
  addSeparator?: boolean;
}
const PortfolioYearlyDataTable: React.FC<FundTableProps> = (props) => {
  const [firstRow, setFirstRow] = useState<RowData[]>([]);
  const [isSelected, setIsSelected] = useState(false);
  const [fundNames, setFundNames] = useState<Record<string, number>[]>([]);
  const { lpName } = useGlobalState();
  const [searchParams] = useSearchParams();
  const paramFundID = searchParams.get("fundID");
  const paramCompanyID = searchParams.get("companyID");
  const paramreportingDate = searchParams.get("reportingDate");

  // const [isdata, setIsData] = useState(false);
  const [portfolioNames, setPortfolioNames] = useState<
    Record<string, number>[]
  >([]);
  const [selectionData, setSelectionData] = useState({
    fund_ID: "",
    company_ID: "",
    date: "",
  });

  useEffect(() => {
    getFundNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpName]);



  const getPortfolioNames = async (id: string) => {
    try {
      const response = await axiosInstance.get("/getCompanies/" + id);
      const responseData = response.data.data;

      // Map the portfolio names and sort them alphabetically by company name
      const portfolioNames = responseData
        .map((portfolio: IPortfolioStatic) => {
          return {
            [portfolio.portfolio_companyName as string]: portfolio.company_ID,
          };
        })
        .sort((a: { [key: string]: string }, b: { [key: string]: string }) => {
          const nameA = Object.keys(a)[0].toLowerCase();
          const nameB = Object.keys(b)[0].toLowerCase();
          return nameA.localeCompare(nameB);
        });

      // Check if portfolioNames is a valid array and handle the selection
      if (
        Array.isArray(portfolioNames) &&
        portfolioNames.length > 0 &&
        typeof portfolioNames[0] === "object" &&
        portfolioNames[0] !== null
      ) {
        const values = Object.values(portfolioNames[0]) as number[];
        if (values.length > 0) {
          setSelectionData({
            fund_ID: id,
            company_ID: values[0].toString(),
            date: "31-03-2021",
          });
        } else {
          console.error("First object in the array is empty.");
        }
      } else {
        console.error("portfolioNames is not a valid array of objects.");
      }

      // Store the sorted portfolio names in the state
      setPortfolioNames(portfolioNames);
    } catch (error) {
      console.error(error);
    }
  };

  const getFundNames = async () => {
    try {
      const response = await axiosInstance.get("/fund-names/" + lpName);
      const responseData = response.data.data;

      // Map the fund names and sort them alphabetically
      const fundNames = responseData
        .map((fund: IFundStatic) => {
          return { [fund.Fund_Name]: fund.fund_ID };
        })
        .sort((a: { [key: string]: string }, b: { [key: string]: string }) => {
          const nameA = Object.keys(a)[0].toLowerCase();
          const nameB = Object.keys(b)[0].toLowerCase();
          return nameA.localeCompare(nameB);
        });

      // Store sorted fund names in the state
      setFundNames(fundNames);

      if (fundNames.length > 0) {
        const firstFundId = Object.values(fundNames[0])[0] as string;
        getPortfolioNames(firstFundId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (value: string, idx: number, key: string) => {
    const updatedRows = [...firstRow];
    updatedRows[idx][key] = value;
    setFirstRow(updatedRows);
  };
  const handleSelectionChange = (data: {
    fund_ID: string;
    company_ID: string;
    date: string;
  }) => {
    if (data.fund_ID !== selectionData.fund_ID && data.fund_ID !== "") {
      getPortfolioNames(data.fund_ID);
    }
    setSelectionData(data);
  };

  const createDataObject = (
    mainKey: string,
    data: PortfolioData[],
    key: keyof PortfolioData,
    prefix: string,
    currentYear: number
  ): Record<string, string | number> => {
    // createDataObject(
    //   "Number of Employees",
    //   portfolioData,
    //   "portfolio_noOfEmployee",
    //   "EmpCnt",
    //   currentYear
    // ),

    const obj: Record<string, string | number> = {
      main_key: mainKey,
      ">12mos": "",
      [`${prefix}_Initial`]: 0,
      [`${prefix}_LastFY03`]: "",
      [`${prefix}_LastFY02`]: "",
      [`${prefix}_LastFY01`]: "",
      [`${prefix}_LastFY`]: "",
      [`${prefix}_CurrentFY`]: "",
      [`${prefix}_CurrentFYE`]: 0,
      [`${prefix}_NextFYE`]: "",
    };

    data.forEach((item) => {
      const yearSuffix = getYearSuffix(item.Reporting_Year, currentYear);
      obj[`${prefix}_${yearSuffix}`] = item[key] || 0;
      if (yearSuffix === "CurrentFY") {
        obj[`${prefix}_CurrentFY`] = item[key] || 0;
        obj[`${prefix}_Initial`] = item[key + "_Initial"] || 0;
        obj[`${prefix}_CurrentFYE`] = item[key + "_Estimated"] || 0;
        obj[`${prefix}_NextFYE`] = item[key + "_Projected"] || 0;
      }
    });
    return obj;
  };
  const getYearSuffix = (
    reportingYear: number,
    currentYear: number
  ): string => {
    const yearDifference = currentYear - reportingYear;
    if (yearDifference === 0) return "CurrentFY";
    if (yearDifference === 1) return "LastFY";
    if (yearDifference === 2) return "LastFY01";
    if (yearDifference === 3) return "LastFY02";
    if (yearDifference === 4) return "LastFY03";
    return `LastFY0${yearDifference}`;
  };
  const getPortfolioReviewData = async () => {
    try {
      const response = await axiosInstance.get(
        `portfolio-yearly-data/${selectionData.fund_ID}/${
          selectionData.company_ID
        }/${selectionData.date.split("-")[2]}/TC2009`
      );

      if (response.status === 200 && response.data.data.length > 0) {
        const portfolioData: PortfolioHistoricalData[] = response.data.data;
        const currentYear = parseInt(selectionData.date.split("-")[2], 10);
        const results = [
          createDataObject(
            "Number of Employees",
            portfolioData,
            "portfolio_noOfEmployee",
            "EmpCnt",
            currentYear
          ),

          createDataObject(
            "Revenue",
            portfolioData,
            "portfolio_revenue",
            "TopLine",
            currentYear
          ),

          createDataObject(
            "EBITDA",
            portfolioData,
            "portfolio_ebitda",
            "Profitability",
            currentYear
          ),

          createDataObject(
            "Equity Value",
            portfolioData,
            "portfolio_eqval",
            "EqVal",
            currentYear
          ),
          createDataObject(
            "Net Debt (Cash)",
            portfolioData,
            "portfolio_Net_Debt_Cash",
            "Net_Debt_Cash",
            currentYear
          ),
          createDataObject(
            "Enterprise Value",
            portfolioData,
            "portfolio_entval",
            "EntValue",
            currentYear
          ),
        ];
        setFirstRow(results);
        setIsSelected(true);
      }
    } catch (error) {
      console.error("Request failed:", error);
    }
  };
  const TableHeading: React.FC<TableHeadingProps> = ({
    children,
    colSpan,
    isFirstColumn,
    isFirstRow,
    addSeparator,
  }) => {
    return (
      <TableCell
        colSpan={colSpan}
        style={{
          borderBottom: 0,
          borderRight: addSeparator ? "2px solid #ddd" : "none",
          paddingBottom: isFirstRow ? 0 : 3,
          textAlign: isFirstColumn ? "left" : "center",
          backgroundColor: "#1E2A38",
          color: "white",
          fontWeight: "bolder",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          justifyContent: "center",
          padding: isFirstRow ? "0px" : "8px",
          paddingTop: isFirstRow ? "8px" : "5px",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle2">{children}</Typography>
      </TableCell>
    );
  };
  const renderTableContent = () => {
    const CurrentYear = parseInt(selectionData.date.split("-")[2], 10);
    const headingData = {
      "Fiscal Year": "Number of Employees",
      "> 12 mos": "",
      Initial: "20",
      [`${CurrentYear - 4}A`]: "162",
      [`${CurrentYear - 3}A`]: "n/a",
      [`${CurrentYear - 2}A`]: "n/a",
      [`${CurrentYear - 1}A`]: "n/a",
      "YTD 3 Mo": "n/a",
      [`${CurrentYear}E`]: "",
      [`${CurrentYear + 1}F`]: "",
    };

    return (
      <div>
        <div style={{ display: "flex" }}>
          <div>
            <PortfolioSelectionComponent
              fundNames={fundNames}
              portfolioNames={portfolioNames}
              onSelectionChange={handleSelectionChange}
            />
          </div>
        </div>
        <div>
          <Button
            onClick={getPortfolioReviewData}
            style={{
              margin: "5px",
              marginLeft: "20px",
              marginBottom: "20px",
              borderRadius: "18px",
              backgroundColor: "#007bff",
            }}
            variant="contained"
          >
            Get Data
          </Button>
        </div>
        {isSelected && (
          <div
            style={{
              padding: "10px",
            }}
          >
            <Table>
              {/* Table Header */}
              <TableHead>
                <TableRow>
                  <TableHeading colSpan={1} isFirstRow>
                    {" "}
                  </TableHeading>
                  <TableHeading colSpan={1} isFirstRow>
                    Est. Runway Next FY
                  </TableHeading>
                  <TableHeading colSpan={1} addSeparator isFirstRow>
                    {" "}
                  </TableHeading>
                  <TableHeading colSpan={4} addSeparator isFirstRow>
                    Actuals
                  </TableHeading>
                  <TableHeading colSpan={2} addSeparator isFirstRow>
                    Current FY
                  </TableHeading>
                  <TableHeading colSpan={1} isFirstRow>
                    Est. Runway Next FY
                  </TableHeading>
                </TableRow>
                <TableRow>
                  {Object.keys(headingData)
                    .filter((key) => key !== "company_ID")
                    .map((heading, idx) => (
                      <TableHeading
                        key={idx}
                        isFirstColumn={idx === 0}
                        addSeparator={[2, 6, 7, 8].includes(idx)}
                      >
                        {heading
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </TableHeading>
                    ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {firstRow.map((item, idx) => (
                  <TableRow key={idx}>
                    <TableCell
                      style={{
                        backgroundColor: idx % 2 === 0 ? "#FFFFFF" : "#EFF5FF",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "200px",
                      }}
                    >
                      <Typography variant="subtitle2">
                        {item.main_key}
                      </Typography>
                    </TableCell>

                    {/* Render other fields: editable */}
                    {Object.entries(item).map(([key, value], index) => {
                      if (key === "main_key") return null; // Skip the first column

                      return (
                        <TableCell
                          key={index}
                          style={{
                            backgroundColor:
                              idx % 2 === 0 ? "#FFFFFF" : "#EFF5FF",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "200px",
                            textAlign: "center",
                          }}
                        >
                          <TextField
                            fullWidth
                            variant="standard"
                            value={value != null ? value.toString() : ""}
                            InputProps={{
                              style: {
                                textAlign: "center",
                                alignItems: "center",
                                display: "flex",
                              },
                            }}
                            onChange={(e) =>
                              handleInputChange(e.target.value, idx, key)
                            }
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    );
  };
  const convertToApiFormat = (tableData: any[], currentYear: number) => {
    // Initialize an object to hold data for each year
    const yearData: Record<number, any> = {};

    // Helper function to assign values based on the year suffix
    const assignYearlyData = (
      yearSuffix: string,
      field: string,
      value: number | string,
      isEstimatedOrProjected: boolean = false
    ) => {
      let yearOffset = 0;
      switch (yearSuffix) {
        case "CurrentFY":
          yearOffset = 0;
          break;
        case "LastFY":
          yearOffset = 1;
          break;
        case "LastFY01":
          yearOffset = 2;
          break;
        case "LastFY02":
          yearOffset = 3;
          break;
        case "LastFY03":
          yearOffset = 4;
          break;
        default:
          return; // Ignore invalid suffix
      }

      const reportingYear = currentYear - yearOffset;
      if (!yearData[reportingYear]) {
        yearData[reportingYear] = {
          Reporting_Year: reportingYear,
          portfolio_Net_Debt_Cash: 0,
          portfolio_ebitda: 0,
          portfolio_entval: 0,
          portfolio_eqval: 0,
          portfolio_noOfEmployee: 0,
          portfolio_revenue: 0,
          portfolio_Net_Debt_Cash_Estimated: 0,
          portfolio_Net_Debt_Cash_Projected: 0,
          portfolio_ebitda_Estimated: 0,
          portfolio_ebitda_Projected: 0,
          portfolio_entval_Estimated: 0,
          portfolio_entval_Projected: 0,
          portfolio_eqval_Estimated: 0,
          portfolio_eqval_Projected: 0,
          portfolio_noOfEmployee_Estimated: 0,
          portfolio_noOfEmployee_Projected: 0,
          portfolio_revenue_Estimated: 0,
          portfolio_revenue_Projected: 0,
          portfolio_Net_Debt_Cash_Initial: 0,
          portfolio_ebitda_Initial: 0,
          portfolio_entval_Initial: 0,
          portfolio_eqval_Initial: 0,
          portfolio_noOfEmployee_Initial: 0,
          portfolio_revenue_Initial: 0,
          fund_ID: "F000002", // Use actual fund_ID
          company_ID: "C000029", // Use actual company_ID
          LP_name: "TC2009", // Use actual LP_name
        };
      }

      // Assign the value to the correct field
      if (!isEstimatedOrProjected || yearSuffix === "CurrentFY") {
        yearData[reportingYear][field] = value || 0;
      }
    };

    // Loop over the table data to map it back to the original format
    tableData.forEach((row) => {
      switch (row.main_key) {
        case "Number of Employees":
          assignYearlyData(
            "CurrentFY",
            "portfolio_noOfEmployee",
            row.EmpCnt_CurrentFY
          );
          assignYearlyData(
            "LastFY",
            "portfolio_noOfEmployee",
            row.EmpCnt_LastFY
          );
          assignYearlyData(
            "LastFY01",
            "portfolio_noOfEmployee",
            row.EmpCnt_LastFY01
          );
          assignYearlyData(
            "LastFY02",
            "portfolio_noOfEmployee",
            row.EmpCnt_LastFY02
          );
          assignYearlyData(
            "LastFY03",
            "portfolio_noOfEmployee",
            row.EmpCnt_LastFY03
          );
          yearData[currentYear].portfolio_noOfEmployee_Initial =
            row.EmpCnt_Initial;
          assignYearlyData(
            "CurrentFY",
            "portfolio_noOfEmployee_Estimated",
            row.EmpCnt_CurrentFYE,
            true
          );
          assignYearlyData(
            "CurrentFY",
            "portfolio_noOfEmployee_Projected",
            row.EmpCnt_NextFYE,
            true
          );
          break;
        case "Revenue":
          assignYearlyData(
            "CurrentFY",
            "portfolio_revenue",
            row.TopLine_CurrentFY
          );
          assignYearlyData("LastFY", "portfolio_revenue", row.TopLine_LastFY);
          assignYearlyData(
            "LastFY01",
            "portfolio_revenue",
            row.TopLine_LastFY01
          );
          assignYearlyData(
            "LastFY02",
            "portfolio_revenue",
            row.TopLine_LastFY02
          );
          assignYearlyData(
            "LastFY03",
            "portfolio_revenue",
            row.TopLine_LastFY03
          );
          yearData[currentYear].portfolio_revenue_Initial = row.TopLine_Initial;
          assignYearlyData(
            "CurrentFY",
            "portfolio_revenue_Estimated",
            row.TopLine_CurrentFYE,
            true
          );
          assignYearlyData(
            "CurrentFY",
            "portfolio_revenue_Projected",
            row.TopLine_NextFYE,
            true
          );
          break;
        case "EBITDA":
          assignYearlyData(
            "CurrentFY",
            "portfolio_ebitda",
            row.Profitability_CurrentFY
          );
          assignYearlyData(
            "LastFY",
            "portfolio_ebitda",
            row.Profitability_LastFY
          );
          assignYearlyData(
            "LastFY01",
            "portfolio_ebitda",
            row.Profitability_LastFY01
          );
          assignYearlyData(
            "LastFY02",
            "portfolio_ebitda",
            row.Profitability_LastFY02
          );
          assignYearlyData(
            "LastFY03",
            "portfolio_ebitda",
            row.Profitability_LastFY03
          );
          yearData[currentYear].portfolio_ebitda_Initial =
            row.Profitability_Initial;
          assignYearlyData(
            "CurrentFY",
            "portfolio_ebitda_Estimated",
            row.Profitability_CurrentFYE,
            true
          );
          assignYearlyData(
            "CurrentFY",
            "portfolio_ebitda_Projected",
            row.Profitability_NextFYE,
            true
          );
          break;
        case "Equity Value":
          assignYearlyData("CurrentFY", "portfolio_eqval", row.EqVal_CurrentFY);
          assignYearlyData("LastFY", "portfolio_eqval", row.EqVal_LastFY);
          assignYearlyData("LastFY01", "portfolio_eqval", row.EqVal_LastFY01);
          assignYearlyData("LastFY02", "portfolio_eqval", row.EqVal_LastFY02);
          assignYearlyData("LastFY03", "portfolio_eqval", row.EqVal_LastFY03);
          yearData[currentYear].portfolio_eqval_Initial = row.EqVal_Initial;
          assignYearlyData(
            "CurrentFY",
            "portfolio_eqval_Estimated",
            row.EqVal_CurrentFYE,
            true
          );
          assignYearlyData(
            "CurrentFY",
            "portfolio_eqval_Projected",
            row.EqVal_NextFYE,
            true
          );
          break;
        case "Net Debt (Cash)":
          assignYearlyData(
            "CurrentFY",
            "portfolio_Net_Debt_Cash",
            row.Net_Debt_Cash_CurrentFY
          );
          assignYearlyData(
            "LastFY",
            "portfolio_Net_Debt_Cash",
            row.Net_Debt_Cash_LastFY
          );
          assignYearlyData(
            "LastFY01",
            "portfolio_Net_Debt_Cash",
            row.Net_Debt_Cash_LastFY01
          );
          assignYearlyData(
            "LastFY02",
            "portfolio_Net_Debt_Cash",
            row.Net_Debt_Cash_LastFY02
          );
          assignYearlyData(
            "LastFY03",
            "portfolio_Net_Debt_Cash",
            row.Net_Debt_Cash_LastFY03
          );
          yearData[currentYear].portfolio_Net_Debt_Cash_Initial =
            row.Net_Debt_Cash_Initial;
          assignYearlyData(
            "CurrentFY",
            "portfolio_Net_Debt_Cash_Estimated",
            row.Net_Debt_Cash_CurrentFYE,
            true
          );
          assignYearlyData(
            "CurrentFY",
            "portfolio_Net_Debt_Cash_Projected",
            row.Net_Debt_Cash_NextFYE,
            true
          );
          break;
        case "Enterprise Value":
          assignYearlyData(
            "CurrentFY",
            "portfolio_entval",
            row.EntValue_CurrentFY
          );
          assignYearlyData("LastFY", "portfolio_entval", row.EntValue_LastFY);
          assignYearlyData(
            "LastFY01",
            "portfolio_entval",
            row.EntValue_LastFY01
          );
          assignYearlyData(
            "LastFY02",
            "portfolio_entval",
            row.EntValue_LastFY02
          );
          assignYearlyData(
            "LastFY03",
            "portfolio_entval",
            row.EntValue_LastFY03
          );
          yearData[currentYear].portfolio_entval_Initial = row.EntValue_Initial;
          assignYearlyData(
            "CurrentFY",
            "portfolio_entval_Estimated",
            row.EntValue_CurrentFYE,
            true
          );
          assignYearlyData(
            "CurrentFY",
            "portfolio_entval_Projected",
            row.EntValue_NextFYE,
            true
          );
          break;
        default:
          break;
      }
    });

    // Return the data as an array
    return Object.values(yearData);
  };

  const PostDataToServer = async () => {
    try {
      setIsSelected(false);
      const reversedData = convertToApiFormat(
        firstRow,
        parseInt(selectionData.date.split("-")[2])
      );
      const form = new FormData();
      form.append("company_ID", selectionData.company_ID);
      form.append("fund_ID", selectionData.fund_ID);
      form.append("formData", JSON.stringify(reversedData));
      form.append("LP_name", "TC2009");

      const response = await axiosInstance.put("/portfolio-yearly", form);
      if (response.status === 200) {
        toast.success("Portfolio review data updated successfully!");
      } else {
        toast.error("Failed to update portfolio review data.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to update portfolio review data.");
    } finally {
      setTimeout(() => {
        getPortfolioReviewData();
      }, 1500);
    }
  };

  return (
    <div>
      <Helmet title="Portfolio Review" />
      <Typography variant="h3" gutterBottom display="inline">
      Key Metrics Form
      </Typography>
      <div
        style={{
          marginTop: "0px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/">
            Dashboard
          </Link>
          <Link component={NavLink} to="/workFlow">
            WorkFlow
          </Link>
          <Typography>Key Metrics Update</Typography>
        </Breadcrumbs>
      </div>
      <Divider my={6} />
      <Paper style={{ width: "100%" }}>
        <Table stickyHeader>{renderTableContent()}</Table>
        {isSelected && (
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              paddingBottom: "30px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={PostDataToServer}
              style={{
                margin: "5px",
                marginLeft: "20px",
                backgroundColor: "#007bff",
                borderRadius: "18px",
              }}
              variant="contained"
            >
              Update
            </Button>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default PortfolioYearlyDataTable;
