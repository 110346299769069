import React, { useState } from "react";
import styled from "@emotion/styled";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";
import { is } from "date-fns/locale";
import { useGlobalState } from "../../contexts/GlobalStateContext";
import CustomModal from "./CustomModal";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const { logout, isAuthenticated, user } = useAuth0();
  const [openModel, setOpenModel] = React.useState(false);
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const { lpName, setlpName } = useGlobalState();

  const [selectedOption, setSelectedOption] = useState<string>(() => {
    if (typeof window !== "undefined") {
      return (
        localStorage.getItem("selectedOption") ||
        process.env.REACT_APP_LP_NAME ||
        "TC2009"
      );
    }
    return process.env.REACT_APP_LP_NAME || "TC2009";
  });
  const changePreference = () => {
    setOpenModel(!openModel);
    closeMenu();
  };
  const handleCloseModal = () => {
    setOpenModel(false);
  };
  const handleUpdate = (selectedOption: string) => {
    setSelectedOption(selectedOption);
    setlpName(selectedOption);
    localStorage.setItem("selectedOption", selectedOption); // Save to localStorage
  };
  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    logout({
      returnTo: process.env.REACT_APP_URL,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    });
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {user && user.Userroles && user.Userroles.length > 0 && (
          <MenuItem onClick={closeMenu}>{user["Userroles"][0]}</MenuItem>
        )}
        {process.env.REACT_APP_NAME === "teralys" && (
          <MenuItem onClick={changePreference}>Change Preference</MenuItem>
        )}
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
      <CustomModal
        open={openModel}
        handleClose={handleCloseModal}
        onUpdate={handleUpdate}
        defaultSelectedOption={selectedOption}
      />
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
