// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

import Page404 from "./pages/auth/Page404";

// Landing
import Landing from "./pages/presentation/Landing";

import NewsPage from "./pages/pages/news";
import Schema from "./pages/pages/schema";
import AddData from "./pages/pages/Add_UpdateData/addData";
import AddLogo from "./pages/pages/Add_UpdateData/add_logo";
import ProtectedRoute from "./components/Auth0/ProtectedRoutes";
import Dashboard from "./layouts/Dashboard";
import UpdateScriptData from "./pages/pages/workflow/Quarterly_Report/Update_script_data";
import AddExtraction from "./pages/pages/workflow/Quarterly_Report/addExtraction";
import Update_AIData from "./pages/pages/Update_FE/AI_Dashboard/Update_AIData";
import AddESG from "./pages/pages/Update_FE/ESG_Update/updateESG";
import FoFForm from "./pages/pages/workflow/FOF/fof_quarter";
import Distribution from "./pages/pages/workflow/distribution_call/distribution";
import CapitalCall from "./pages/pages/workflow/capital_call/capital_call";
import Portfolio_Review from "./pages/pages/workflow/PortfolioReview/Portfolio_Review_table";
import Market_watch from "./pages/pages/workflow/Market_watch/marketWatch";
import WorkflowPage from "./pages/pages/workflow/workFlow";
import AICapitalCall from "./pages/pages/workflow/capital_call/AI_CapitalCall";
import AIDistributionCall from "./pages/pages/workflow/distribution_call/AI_DistributionCall";
import CapitalCalAIUpdate from "./pages/pages/workflow/capital_call/AI_capitalCalUpdate";
import FundOnboarding from "./pages/pages/fund_onboarding";
import PortfolioReview from "./pages/pages/workflow/PortfolioReview/portfolio_review";
import Tools from "./pages/pages/Tools/Tools";
import DistributionCallAIUpdate from "./pages/pages/workflow/distribution_call/AI_distributionCallUpdate";
import PortfolioReviewTable from "./components/Workflow/PortfolioReviewTable";
import UpdateNotes from "./pages/pages/workflow/Update_Notes/update_notes";
import Update_Funds from "./pages/pages/Update_FE/Fund_Raising/update_fundRaising";
import AddPortfolioReviewExtraction from "./pages/pages/workflow/Portfolio_ReviewAI_Tool/AddExtraction";
import RunStatics from "./pages/pages/RunStatus/Runstatics";
import PortfolioYearlyDataTable from "./components/Workflow/portfolioyearlyDataTable";
import DataFinder from "./pages/pages/DataFinder/Datafinder";

const routes = [
  {
    path: "/",
    element: <Dashboard />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={Landing} />,
      },
    ],
  },
  {
    path: "pdf_extract",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={Tools} />,
      },
    ],
  },
  {
    path: "AI_extract",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={AddExtraction} />,
      },
    ],
  },
  {
    path: "portfolio_summary",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={NewsPage} />,
      },
    ],
  },
  {
    path: "schema_generator",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={Schema} />,
      },
    ],
  },
  {
    path: "add_data",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={AddData} />,
      },
    ],
  },
  {
    path: "Update_script_data",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={UpdateScriptData} />,
      },
    ],
  },
  {
    path: "add_pdf",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={AddLogo} />,
      },
    ],
  },
  {
    path: "update_fundRaising",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={Update_Funds} />,
      },
    ],
  },
  {
    path: "Update_AIData",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={Update_AIData} />,
      },
    ],
  },
  {
    path: "update_ESG",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={AddESG} />,
      },
    ],
  },
  {
    path: "update_FOF",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={FoFForm} />,
      },
    ],
  },
  {
    path: "update_CapitalCall",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={CapitalCall} />,
      },
    ],
  },
  {
    path: "update_Distribution",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={Distribution} />,
      },
    ],
  },
  {
    path: "update_PortfolioReview",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={PortfolioReview} />,
      },
    ],
  },
  {
    path: "update_PortfolioReviewTable",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={PortfolioReviewTable} />,
      },
    ],
  },
  {
    path: "update_PortfolioYearlyTable",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={PortfolioYearlyDataTable} />,
      },
    ],
  },
  {
    path: "update_PortfolioReviewData",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={Portfolio_Review} />,
      },
    ],
  },
  {
    path: "update_MarketWatch",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={Market_watch} />,
      },
    ],
  },
  {
    path: "AI_CapitalCall",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={AICapitalCall} />,
      },
    ],
  },
  {
    path: "AI_CapitalCall_Update",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={CapitalCalAIUpdate} />,
      },
    ],
  },
  {
    path: "AI_DistributionCall_Update",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={DistributionCallAIUpdate} />,
      },
    ],
  },
  {
    path: "AI_DisributionCall",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={AIDistributionCall} />,
      },
    ],
  },
  {
    path: "Workflow",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={WorkflowPage} />,
      },
    ],
  },
  {
    path: "Fund_onboarding",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={FundOnboarding} />,
      },
    ],
  },
  {
    path: "Update_notes",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={UpdateNotes} />,
      },
    ],
  },
  {
    path: "AddPortfolioReview_Extraction",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={AddPortfolioReviewExtraction} />,
      },
    ],
  },
  {
    path: "Run_Statistics",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={RunStatics} />,
      },
    ],
  },
  {
    path: "Smart_Data_Finder",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={DataFinder} />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <ProtectedRoute component={Page404} />,
      },
    ],
  },
];

export default routes;
