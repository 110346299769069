import React, { ReactElement, useEffect } from "react";
import { useState } from "react";
import DashboardLayout from "../../../../layouts/Dashboard";
import {
  CapitalCallReport,
  fieldNames,
  orderArray,
} from "../../../../interfaces/capitalCall.interface";
import { Helmet } from "react-helmet-async";
import { Box, Button, Paper } from "@mui/material";
import axiosInstance from "../../../../utils/axios/axios.instance";
import { DatePicker } from "@mui/x-date-pickers";
import DynamicForm3 from "../../../../components/Workflow/workFlow_dataUpdateForm";
import ExportCSVButton from "../../../../components/ExportCSVButton";
import { toast } from "react-toastify";
import { useGlobalState } from "../../../../contexts/GlobalStateContext";

function CapitalCall() {
  const [fundName, setFundNames] = useState(["None"]);
  const [fundIDs, setFundIDs] = useState(["None"]); // fundIDs[0] = fundName[0]
  const [selectedFundID, setSelectedFundID] = useState("");
  const [selectedFundName, setSelectedFundName] = useState("");
  const [date, setDate] = useState<Date | null>(new Date());
  const [selectedCapitalCall, setSelectedCapitalCall] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [fieldData, setFieldData] = useState({});
  const [fieldData2, setFieldData2] = useState({});
  const [isPreviousData, setIsPreviosuData] = useState(false);
  const { lpName } = useGlobalState();

  function createFieldData(responseData: CapitalCallReport): {
    [key: string]: {
      type: string | Date;
      defaultValue: string | undefined | Date | File | Boolean;
      name: string | undefined;
    };
  } {
    const excludedFields = ["_id", "cc_ID", "fund_ID"];
    const fieldData: {
      [key: string]: {
        type: string | Date;
        defaultValue: string | undefined | Date | File | Boolean;
        name: string | undefined;
      };
    } = {};

    orderArray.forEach((key) => {
      if (excludedFields.includes(key) || !(key in responseData)) {
        return;
      }
      let value = "";
      if (key === "cc_Date") {
        value = formatDateString(responseData[key as keyof CapitalCallReport]);
      } else value = responseData[key as keyof CapitalCallReport];
      let type: string;

      if (typeof value === "string") {
        type = "text";
      } else if (typeof value === "boolean") {
        type = "checkbox";
      } else if (typeof value === "number") {
        type = "number";
      } else if (Array.isArray(value)) {
        type = "array";
      } else {
        type = "string";
      }

      fieldData[key] = {
        type,
        defaultValue: value,
        name: fieldNames?.[key as keyof CapitalCallReport],
      };
    });

    return fieldData;
  }

  function formatDateString(dateStr: string) {
    if (dateStr.includes("T")) {
      const date = new Date(dateStr);
      return `${String(date.getDate()).padStart(2, "0")}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${date.getFullYear()}`;
    } else {
      const [day, month, year] = dateStr.split("-");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    }
  }
  const fetchData = async (id: string, id2: string) => {
    try {
      setFieldData({});
      const response = await axiosInstance.get(
        `/getCapitalCallDataByID/${id2}/${id}`
      );
      const response2 = await axiosInstance.get(
        `/getCapitalCalCurrentData/${id2}/${id}`
      );
      if (response.data === "error") {
        toast.error("Error wh`ile fetching Data.");
      } else {
        if (response.data.data.length === 0) {
          toast.error("There is no data with the given Date");
        } else {
          const responseData = response.data.data[0];
          const responseData2 = response2.data.data[0];
          const data = responseData.cc_Date
            .split("T")[0]
            .split("-")
            .reverse()
            .join("-");

          responseData.cc_Date = data;
          if (responseData2 !== undefined) {
            setIsPreviosuData(true);
            const data2 = responseData2["cc_Date"]
              .split("T")[0]
              .split("-")
              .reverse()
              .join("-");
            responseData2.cc_Date = data2;
            setFieldData2(createFieldData(responseData2));
          } else {
            setIsPreviosuData(false);
            setFieldData2(
              createFieldData({
                _id: "",
                cc_Date: id,
                cc_ID: "",
                Fund_Name: id2,
                fund_currency: "",
                cc_investment: "",
                cc_managementFees: "",
                cc_orgFees: "",
                cc_other: "",
                cc_VAT: "",
                cc_partnershipExp: "",
                cc_totalCapitalCalled: "",
                fund_ID: "",
                cc_LPname_totalcapitalcalled: "",
                cc_No: "",
              })
            );
          }
          setIsSelected(true);
          setFieldData(createFieldData(responseData));
        }
      }
    } catch (err) {
      toast.error("Error while fetching Data.");
      console.error(err);
    }
  };

  const handleChangeData = async (formData: {
    [fieldName: string]: string | undefined | Date | File | Boolean | Number;
  }) => {
    try {
      const form = new FormData();
      formData["cc_Date"] = date?.toISOString();
      formData["cc_ID"] = selectedCapitalCall + selectedFundName;
      formData["fund_ID"] = selectedFundID;
      Object.keys(formData).forEach((key) => {
        let value = formData[key];
        if (value === null) {
          // Assume string fields should default to "", numeric fields to 0
          // You might need more complex logic here if you have specific fields that need different handling
          value = typeof formData[key] === "number" ? 0 : "";
        }

        // Append the key and either the original or the replaced value to the form
        form.append(key, value as string);
      });
      form.append("updateKey", isPreviousData ? "true" : "false");
      const response = await axiosInstance.post("/add_capitalCallData", form, {
        headers: {
          "Content-Type": "multipart/form-data", // or you can omit this line
        },
      });
      if (response.data.data === "error") {
        toast.error("Error while updating Data.");
      } else {
        setFieldData({});
        toast.success("Data updated successfully");
      }
    } catch (err) {
      toast.error("Error while updating Data.");
    } finally {
      setTimeout(() => {
        fetchData(selectedCapitalCall, selectedFundID);
      }, 3000);
    }
  };
  const getFunds = async () => {
    try {
      const response = await axiosInstance.get("/fund-names/" + lpName);
      if (response.data === "error") {
        toast.error("Error while fetching Data.");
      } else {
        const responseData = response.data.data;
        let fundNames: string[] = [];
        let fundIDs: string[] = [];
        responseData.sort(
          (a: { Fund_Name: string }, b: { Fund_Name: string }) => {
            return a.Fund_Name.toLowerCase().localeCompare(
              b.Fund_Name.toLowerCase()
            );
          }
        );
        responseData.forEach(
          (element: { Fund_Name: string; fund_ID: string }) => {
            fundNames.push(element.Fund_Name);
            fundIDs.push(element.fund_ID);
          }
        );
        setFundIDs(fundIDs);
        setFundNames(fundNames);
        setSelectedFundName(fundNames[0]);
        setSelectedFundID(fundIDs[0]);
      }
    } catch (err) {
      toast.error("Error while fetching Data.");
      console.error(err);
    }
  };
  useEffect(() => {
    setSelectedCapitalCall(formatDate(new Date()));
  }, []);
  useEffect(() => {
    getFunds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpName]);
  function formatDate(dateString: Date) {
    const day = String(dateString.getDate()).padStart(2, "0");
    const month = String(dateString.getMonth() + 1).padStart(2, "0"); // +1 because getMonth() returns 0-11
    const year = dateString.getFullYear();
    return `${day}-${month}-${year}`;
  }
  return (
    <React.Fragment>
      <Helmet title="Add Reporting Data" />
      <Paper
        style={{
          padding: "10px",
        }}
      >
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h1>CapitalCall Form</h1>
            <ExportCSVButton collection="capitalCall" />
          </div>
          <Box
            display="flex"
            // justifyContent="space-between"
            alignItems="center"
            padding="10px"
          >
            <div>
              <label htmlFor="fund-name-select">Fund Name: </label>
              <select
                id="fund-name-select"
                onChange={(e) => {
                  setSelectedFundName(e.target.value);
                  setSelectedFundID(fundIDs[fundName.indexOf(e.target.value)]);
                  // fetchData(selectedCapitalCall, e.target.value);
                }}
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "#333",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  outline: "none",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                  width: "200px",
                  cursor: "pointer",
                  height: "52px",
                }}
              >
                {fundName.map((fund, index) => (
                  <option key={index} value={fund}>
                    {fund}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ marginLeft: "5%" }}>
              <label
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  fontWeight: "bold",
                }}
              >
                Select a date :
              </label>
              <DatePicker
                format="dd-MM-yyyy"
                label="Reporting Date"
                value={date}
                onChange={(newValue) => {
                  if (newValue !== null) {
                    // fetchData(formatDate(newValue), selectedFundName);
                    setSelectedCapitalCall(formatDate(newValue));
                    setDate(newValue);
                  }
                }}
              />
            </div>
          </Box>
          <div style={{ marginTop: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{
                  padding: "12px 24px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "18px",
                  cursor: "pointer",
                  fontWeight: "bold",
                  height: "48px",
                }}
                variant="contained"
                color="primary"
                onClick={() => fetchData(selectedCapitalCall, selectedFundID)}
              >
                Get Data
              </Button>
            </div>
          </div>
          {isSelected && (
            <DynamicForm3
              fieldData={fieldData}
              fieldData2={fieldData2}
              changedata={handleChangeData}
              staticData={false}
              date={selectedCapitalCall}
              isUpdate={isPreviousData}
            />
          )}
          {/* {message !== "" && <>{message}</>} */}
        </div>
      </Paper>
    </React.Fragment>
  );
}

CapitalCall.getLayout = function getLayout(page: ReactElement) {
  return <DashboardLayout>{page}</DashboardLayout>;
};
export default CapitalCall;
