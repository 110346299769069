export const fundStaticDataFields = [
  {
    Reporting_Date: "",
    fund_InvPer_ExpAct: "",
    fund_strategy: "",
    fund_region: "",
    fund_fundSizeFinalClose: "",
    fund_numberOfInvestmentProjected: "",
    LP_name: "",
    lp_investmentStake: "",
    fund_nav: "",
    fund_dpi: "",
    fund_tvpi: "",
    fund_recallableDistributions: "",
    fund_gpCarriedDistribution: "",
    fund_distributed: "",
    created_At: "",
    record_type: "",
    fund_netIRR: "",
    fund_sectorFocus: "",
    fund_stage: "",
    fund_investmentAdvisor: "",
    fund_investmentManager: "",
    fund_Life_LPA: "",
    fund_gpCommitment: "",
    fund_hurdleRate: "",
    fund_removalWithCause: "",
    fund_removalWithoutCause: "",
    fund_treatmentOfCarriedInterestOnRemoval: "",
    fund_keyPersonProvision: "",
    fund_governingLawAndJurisdiction: "",
    fund_teamBackground: "",
    fund_commitment: "",
    fund_vintage: "",
    fund_currency: "",
    fund_Fees: "",
    fund_Pref: "",
    fund_Carry: "",
    fund_geoLocation: "",
    Textboxes_Strategy: "",
    Textboxes_Outlook: "",
    Textboxes_QtrlyUpdate: "",
    Textboxes_FundPipe: "",
    "Textboxes_Co-InvestPipe": "",
    Textboxes_Exits: "",
    fund_capitalReserved: "",
    fund_capitalAvailable: "",
    fund_numberOfInvestmentToDate: "",
    fund_netAssetValueGPCarried: "",
    fund_capitalInvested: "",
    fund_called: "",
    fund_capitalCalled: "",
    fund_FirstClosing: "",
    fund_percentageFundSizeLPA: "",
    fund_InvPer: "",
    fund_percentageNICLPA: "",
    fund_PartnerExp_toDate: "",
    fund_MgmtFees_toDate: "",
    fund_OrgFees_toDate: "",
    fund_recalledAmount: "",
    run_id: "",
    Fund_Name: "",
    fund_ID: "",
    fund_valueDistributed: "",
    fund_Life_Exp: "",
    fund_expCalledRecalled: "",
    fund_PostInv: "",
    fund_ExpCarry: "",
    BREAK: "",
     BREAK1: ""
  },
];

export const portfolioStaticDataFields = [
  {
    Reporting_Date: "",
    Textboxes_Overview: "",
    PublicMkt_Status: "",
    portfolio_companyCountry: "",
    portfolio_firstInvestmentDate: "",
    portfolio_exitDate: "",
    portfolio_realizedGainLoss: "",
    portfolio_unrealizedCost: "",
    portfolio_unrealizedNAV: "",
    portfolio_currency: "",
    portfolio_companyName: "",
    portfolio_FiscalYearEnd: "",
    positive_impact: "",
    portfolio_realizedValue: "",
    portfolio_geoLocation: "",
    portfolio_companyOverview: "",
    portfolio_companyState: "",
    portfolio_sector: "",
    Textboxes_QtrUpdate: "",
    portfolio_revenue: "",
    portfolio_ebitda: "",
    LP_name: "",
    portfolio_initialInvt_FDO: "",
    created_At: "",
    run_id: "",
    portfolio_CapitalInvested_NonDil: "",
    portfolio_reservedCapital: "",
    portfolio_moicFinal: "",
    portfolio_unrealizedNAV_NonDil: "",
    portfolio_initialInvt_NonDil: "",
    portfolio_initialInvt_Dilutive: "",
    portfolio_capitalInvested: "",
    portfolio_capitalReserved: "",
    portfolio_ROI: "",
    portfolio_unrealizedGainLoss: "",
    portfolio_totalGainLoss: "",
    portfolio_moc: "",
    portfolio_realizedCost: "",
    portfolio_GP_Lead: "",
    FDO: "",
    LastValChange_QQ: "",
    LastValChange_YYYY: "",
    LastValChange_Nature: "",
    Textboxes_Valuation: "",
    "Textboxes_Milestones-ST": "",
    "Textboxes_Outlook-LT": "",
    portfolio_series: "",
    portfolio_investedbyGP: "",
    portfolio_finalsizeRound: "",
    portfolio_noOfEmployee: "",
    portfolio_eqval: "",
    portfolio_entval: "",
    portfolio_Net_Debt_Cash: "",
    Fund_Name: "",
    fund_ID: "",
    company_ID: "",
    Exp_FinalReturn_MOIC: "",
    Exp_FinalReturn_TC_Rating: "",
    portfolio_InvtCD_NonDilutive: "",
    portfolio_unrealizedNAV_Dil: "",
    BREAK: "",
    BREAK1: ""
  },
];

export const updateScriptFundData = (
  converted: string,
  fundName: string,
  FundID: string
) => {
  return [
    {
      Reporting_Date: converted,
      fund_strategy: "",
      Fund_Name: fundName,
      fund_ID: FundID,
      AUC_GPDist: "",
      AUC_GPNav: "",
      AUC_Reserves: "",
      fund_OrgFees_toDate: "",
      fund_PartnerExp_toDate: "",
      fund_MgmtFees_toDate: "",
      Textboxes_Strategy: "",
      Textboxes_Outlook: "",
      Textboxes_QtrlyUpdate: "",
      Textboxes_FundPipe: "",
      "Textboxes_Co-InvestPipe": "",
      Textboxes_Exits: "",
      Textboxes_ToBeRealized: "",
      fund_nav: "",
      fund_dpi: "",
      fund_tvpi: "",
      fund_endDate: "",
      ReportingDateKey: "",
      fund_called: "",
      fund_recallableDistributions: "",
      fund_distributed: "",
      fund_capitalCalled: "",
      fund_capitalReserved: "",
      fund_numberOfInvestmentToDate: "",
      fund_gpCarriedDistribution: "",
      fund_uncalledCapital: "",
      fund_valueDistributed: "",
      fund_netIRR: "",
      fund_rvpi: "",
      fund_numberOfInvestmentProjected: "",
      fund_capitalAvailable: "",
      fund_totalOtherAssetsLiabilities: "",
      fund_ficialSummary: "",
      fund_keyDriversPreviousQTR: "",
      fund_macroInductryDrivers: "",
      fund_extraOrdinaryMovements: "",
      fund_riskDisclosure: "",
      fund_gpUpdates: "",
      fund_corporateActions: "",
      fund_valueNetAsset: "",
      fund_IRR: "",
      LP_name: "",
    },
  ];
};

export const updateScriptPortfolioData = (
  selectedFundId: string,
  companyName: string,
  fundName: string,
  converted: string,
  companyID: string
) => {
  return [
    {
      _id: "",
      Reporting_Date: converted,
      Fund_Name: fundName,
      fund_ID: selectedFundId,
      portfolio_companyName: companyName,
      Main_Company_Name: companyName,
      company_ID: companyID,
      ReportingDateKey: "",
      ReportingDateString: "",
      portfolio_firstInvestmentDate: "",
      portfolio_exitDate: "",
      portfolio_unrealizedCost: "",
      portfolio_unrealizedNAV: "",
      portfolio_realizedValue: "",
      portfolio_latestNav: "",
      portfolio_proceeds: "",
      portfolio_fmv: "",
      portfolio_tvpi: "",
      portfolio_revenue: "",
      portfolio_ebitda: "",
      LP_name: "",
      portfolio_holdingPeriod: "",
      portfolio_totalCumulativeValue: "",
      portfolio_initialInvt_FDO: "",
      portfolio_keyShareholders: "",
      portfolio_arr: "",
      portfolio_ebit: "",
      portfolio_pbt: "",
      portfolio_grossMargin: "",
      Currency: "",
      portfolio_GP_Lead: "",
      InvCostoDate_Dilutive: "",
      Textboxes_QtrUpdate: "",
      FDO: "",
      portfolio_realizedCost: "",
      portfolio_realizedGainLoss: "",
      portfolio_CapitalInvested_NonDil: "",
      portfolio_reservedCapital: "",
      portfolio_moicFinal: "",
      portfolio_unrealizedNAV_NonDil: "",
      portfolio_initialInvt_NonDil: "",
      portfolio_initialInvt_Dilutive: "",
      portfolio_capitalInvested: "",
      portfolio_capitalReserved: "",
      portfolio_ROI: "",
      portfolio_unrealizedGainLoss: "",
      portfolio_totalGainLoss: "",
      portfolio_moc: "",
      PublicMkt_SharePrice: "",
      portfolio_FiscalYearEnd: "",
      PublicMkt_Exchange: "",
      PublicMkt_Ticker: "",
      PublicMkt_Status: "",
      "LastValChange_QQ": "",
      "LastValChange_YYYY": 0,
      "LastValChange_Nature": "",
      "portfolio_InvtCD_NonDilutive": "",
      "portfolio_unrealizedNAV_Dil": "",
      "Exp_FinalReturn_MOIC": 0,
      "Exp_FinalReturn_TC_Rating": "",
      "Textboxes_Outlook-LT": "",
      "Textboxes_Milestones-ST": "",
      "Textboxes_Overview": "",
      "portfolio_entval": 0,
      "portfolio_eqval": 0,
      "portfolio_noOfEmployee": 0,
      "portfolio_finalsizeRound": 0,
      "portfolio_investedbyGP": 0,
      "portfolio_series": "",
      "Textboxes_Valuation": "",
      "portfolio_Net_Debt_Cash": ""

    },
  ];
};

export const updateFundQurterlyData = (
  selectedFundId: string,
  fundName: string,
  Reporting_Date: string,
  ReportingDateKey: string
) => {
  return [
    {
      _id: "",
      Reporting_Date: Reporting_Date,
      Fund_Name: fundName,
      AUC_IRR: 0,
      AUC_GPDist: 0,
      AUC_GPNav: 0,
      AUC_Reserves: 0,

      fund_endDate: "",
      Textboxes_Strategy: "",
      Textboxes_Outlook: "",
      Textboxes_QtrlyUpdate: "",
      Textboxes_FundPipe: "",
      "Textboxes_Co-InvestPipe": "",
      Textboxes_Exits: "",
      Textboxes_ToBeRealized: "",
      fund_ID: selectedFundId,
      ReportingDateKey: `${ReportingDateKey}T00:00:00.000Z`,
      fund_numberOfInvestmentToDate: 0,
      fund_numberOfInvestmentProjected: 0,
      fund_capitalCalled: 0,
      fund_capitalReserved: 0,
      fund_capitalAvailable: 0,
      fund_valueDistributed: 0,
      fund_dpi: 0,
      fund_tvpi: 0,
      fund_called: 0,
      fund_recallableDistributions: 0,
      fund_gpCarriedDistribution: 0,
      fund_netAssetValueGPCarried: 0,
      fund_nav: 0,
      fund_totalOtherAssetsLiabilities: "",
      fund_financialSummary: "",
      fund_keyDriversPreviousQTR: "",
      fund_macroInductryDrivers: "",
      fund_extraOrdinaryMovements: "",
      fund_riskDisclosure: "",
      fund_gpUpdates: "",
      fund_corporateActions: "",
      fund_uncalledCapital: "",
      fund_netIRR: "",
      fund_rvpi: "",
      fund_Textboxes_QtrlyreportUpdate: "",
      fund_valueNetAsset: 0,
      fund_PartnerExp_toDate: 0,
      fund_OrgFees_toDate: 0,
      fund_MgmtFees_toDate: 0,
      fund_distributed: 0,
      fund_capitalInvested: 0,
      fund_carriedInterest: 0,
      fund_ficialSummary: "",
      fund_IRR: 0,
      fund_recalledAmount: "",
      fund_recallableAmount: 0,
      LP_name: "",
    },
  ];
};
