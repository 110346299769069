import React, { RefObject, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TruncateText from "./dialog";
import PdfViewerModal from "../../add_updateForm/PDFModal";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";

interface PortfolioFormProps {
  form: RefObject<HTMLFormElement>;
  pdf_link: string;
  staticValue: Array<Record<string, any>>;
  extractedData: Record<string, any>;
  inputvalue: Record<string, any>;
  handlePortfolioChange: (key: string, value: any) => void;
  constKey: string[];
  keyOrder: string[];
  PortfolioKeyNames: Record<string, string>;
  styles: Record<string, React.CSSProperties>;
  runId: string;
}

const PortfolioForm: React.FC<PortfolioFormProps> = ({
  form,
  staticValue,
  extractedData,
  pdf_link,
  inputvalue,
  handlePortfolioChange,
  constKey,
  keyOrder,
  PortfolioKeyNames,
  styles,
  runId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const portfolioRef = useRef<HTMLDivElement>(null);
  const manualRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState(0); // State to manage active tab

  const handleScrollTo = (ref: RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    if (newValue === 0) {
      handleScrollTo(portfolioRef);
    } else if (newValue === 1) {
      handleScrollTo(manualRef);
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const customSortKeys = (
    keys: string[],
    extractedData: Record<string, any>,
    keyOrder: string[]
  ) => {
    const keysWithoutBreaks = keys.filter(
      (key) => key !== "BREAK1" && key !== "BREAK"
    );
    const beforeBreak1 = keysWithoutBreaks.filter(
      (key) =>
        keyOrder.indexOf(key) !== -1 &&
        keyOrder.indexOf(key) < keyOrder.indexOf("BREAK1")
    );
    const betweenBreak1AndBreak = keysWithoutBreaks.filter(
      (key) =>
        keyOrder.indexOf(key) !== -1 &&
        keyOrder.indexOf(key) >= keyOrder.indexOf("BREAK1") &&
        keyOrder.indexOf(key) < keyOrder.indexOf("BREAK")
    );
    const afterBreak = keysWithoutBreaks.filter(
      (key) =>
        keyOrder.indexOf(key) !== -1 &&
        keyOrder.indexOf(key) >= keyOrder.indexOf("BREAK")
    );
    const sortSection = (sectionKeys: string[]) => {
      return sectionKeys.sort((a, b) => {
        const hasValueA = extractedData[a] ? 1 : 0;
        const hasValueB = extractedData[b] ? 1 : 0;
        if (hasValueA !== hasValueB) {
          return hasValueB - hasValueA;
        }
        return keyOrder.indexOf(a) - keyOrder.indexOf(b);
      });
    };
    const sortedBeforeBreak1 = sortSection(beforeBreak1);
    const sortedBetweenBreak1AndBreak = sortSection(betweenBreak1AndBreak);
    const sortedAfterBreak = sortSection(afterBreak);
    return [
      ...sortedBeforeBreak1,
      "BREAK1",
      ...sortedBetweenBreak1AndBreak,
      "BREAK",
      ...sortedAfterBreak,
    ];
  };

  const renderSectionHeader = (title: string, id?: string) => (
    <div
      style={{
        width: "100%", // Full width
        boxSizing: "border-box", // Include padding/borders in width
        display: "block", // Ensure it's a block element
        textAlign: "center",
        backgroundColor: "#E1E8EE",
        padding: "15px 0",
        borderBottom: "4px solid #2E3A49",
        marginBottom: "10px", // Add space below the header
      }}
      id={id}
      ref={id === "portfolio" ? portfolioRef : manualRef} // Associate ref with the section
    >
      <h3
        style={{
          margin: 0,
          color: "#1B2430",
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        {title}
      </h3>
    </div>
  );

  const renderRow = (key: string, index: number) => {
    let backgroundColor = "#FFFFFF";
    if (keyOrder.indexOf(key) < keyOrder.indexOf("BREAK1")) {
      backgroundColor = "rgba(175, 211, 227, 0.2)";
    } else if (
      keyOrder.indexOf(key) >= keyOrder.indexOf("BREAK1") &&
      keyOrder.indexOf(key) < keyOrder.indexOf("BREAK")
    ) {
      backgroundColor = "rgba(184, 224, 199, 0.2)";
    } else if (keyOrder.indexOf(key) >= keyOrder.indexOf("BREAK")) {
      backgroundColor = "#F2F2F2";
    }
    const file = extractedData;
    const reference = staticValue[0];
    if (key === "_id") return null;
    if (key === "BREAL" || key === "BREAK1") {
      return null;
    }
    // if (key === "BREAK" || key === "BREAK1") {
    //   return key === "BREAK" ? (
    //     <></>
    //   ) : (
    //     <>
    //       {/* Render Manual Key Set outside the table */}
    //       {renderSectionHeader("Manual Key Set", "manual")}
    //       <tr
    //         style={{
    //           backgroundColor: "#F3F6FA",
    //           borderBottom: "2px solid #2E3A49",
    //         }}
    //       >
    //         <th
    //           style={{ ...styles.tableLabel, width: "20%", textAlign: "left" }}
    //         >
    //           Key name
    //         </th>
    //         <th
    //           style={{
    //             ...styles.tableLabel,
    //             textAlign: "center",
    //             width: "25%",
    //           }}
    //         >
    //           Update value manually
    //         </th>
    //         <th
    //           style={{
    //             ...styles.tableLabel,
    //             textAlign: "center",
    //             width: "20%",
    //           }}
    //         >
    //           AI extracted value
    //         </th>
    //         <th
    //           style={{
    //             ...styles.tableLabel,
    //             textAlign: "center",
    //             width: "10%",
    //           }}
    //         >
    //           Pages
    //         </th>
    //         <th
    //           style={{
    //             ...styles.tableLabel,
    //             color: "grey",
    //             textAlign: "center",
    //             width: "25%",
    //           }}
    //         >
    //           Previous Entry
    //         </th>
    //       </tr>
    //       <tr>
    //         <td colSpan={5} style={{ height: "10px" }}></td> {/* Spacer row */}
    //       </tr>
    //     </>
    //   );
    // }

    const label = PortfolioKeyNames[key] || key.replace(/_/g, " ");

    return (
      <tr
        key={index}
        style={{
          paddingTop: "30px",
          borderBottom: "1px solid #cccccc",
          backgroundColor: index % 2 === 0 ? backgroundColor : "#FFFFFF",
        }}
      >
        <td style={{ ...styles.label, paddingBottom: "10px", width: "20%" }}>
          {label}
        </td>
        <td>
          <TextField
            multiline
            maxRows={5}
            minRows={1}
            id={index.toString()}
            name={key}
            // label={label}
            style={{
              width: "100%",
              textAlign: "justify",
              marginBottom: "10px",
              background: "#fff",
              borderColor: "#007bff",
              borderWidth: "2px",
              borderRadius: "4px",
              margin: "5px",
            }}
            value={inputvalue[key]}
            type="text"
            onChange={(e) => handlePortfolioChange(key, e.target.value)}
            disabled={constKey.includes(key)}
          />
        </td>

        {file[key] &&
        runId &&
        (typeof file[key] === "string" || typeof file[key] === "number") ? (
          <>
            <td
              style={{
                ...styles.label,
                textAlign: "center",
                paddingBottom: "10px",
                width: "25%",
              }}
            >
              {<TruncateText text={file[key]} maxWords={40} />}
              {!constKey.includes(key) && (
                <IconButton
                  aria-label="content copy"
                  size="small"
                  onClick={() => handlePortfolioChange(key, file[key])}
                >
                  <ContentCopyIcon fontSize="inherit" />
                </IconButton>
              )}
            </td>
            <td
              style={{
                textAlign: "center",
                width: "10%",
              }}
              onClick={() => {
                setPageNumber(file.page[key]);
                window.open(
                  `${pdf_link}#page=${file.page[key]}&toolbar=0`,
                  "_blank"
                );
                // handleOpenModal();
              }}
            >
              {file.page && key in file.page ? (
                <p
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    textDecoration: "underline",
                  }}
                >
                  {file.page[key]}
                </p>
              ) : (
                <p>-</p>
              )}
            </td>
          </>
        ) : (
          <>
            <td
              style={{
                ...styles.label,
                textAlign: "center",
                paddingBottom: "10px",
                width: "25%",
              }}
            ></td>
            <td
              style={{
                ...styles.label,
                textAlign: "center",
                paddingBottom: "10px",
                width: "5%",
              }}
            ></td>
          </>
        )}

        <td
          style={{
            ...styles.label,
            textAlign: "center",
            color: "grey",
            paddingBottom: "10px",
            width: "25%",
          }}
        >
          {reference[key] === "" ? (
            "-"
          ) : (
            <TruncateText text={reference[key]} maxWords={40} />
          )}
          {reference[key] && !constKey.includes(key) && (
            <IconButton
              aria-label="content copy"
              size="small"
              onClick={() => handlePortfolioChange(key, reference[key])}
            >
              <ContentCopyIcon fontSize="inherit" />
            </IconButton>
          )}
        </td>
      </tr>
    );
  };

  const FormLinks = () => {
    const forms = [
      {
        title: "Edit Static Information",
        description:
          "Navigate to Static Key Set Page to view or update static keys.",
        url: "/add_data",
      },
      {
        title: "Edit Key Metrics",
        description:
          "Navigate to Key Metrics Page to update or add Key Metrics for this portoflio.",
        url: "/update_PortfolioYearlyTable",
      },
      {
        title: "Edit Investment Valuation",
        description:
          "Navigate to Investment History Page to update or add Valuation for this portfolio.",
        url: "/update_PortfolioReviewTable",
      },
    ];

    return (
      <Box
        sx={{
          marginTop: "30px",
          display: "flex",
          flexWrap: "wrap",
          gap: "16px",
          justifyContent: "space-evenly",
        }}
      >
        {forms.map((form, index) => (
          <Box
            key={index}
            sx={{
              backgroundColor: "#f8f9fa",
              borderRadius: "8px",
              padding: "16px",
              maxWidth: "350px",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              transition: "transform 0.2s ease",
              "&:hover": {
                transform: "translateY(-3px)",
                boxShadow: "0 6px 14px rgba(0,0,0,0.15)",
              },
              cursor: "pointer",
            }}
            onClick={() => window.open(form.url, "_blank")}
          >
            <LinkIcon
              sx={{ fontSize: "48px", color: "#007bff", marginBottom: "8px" }}
            />
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "#343a40" }}
            >
              {form.title}
            </Typography>
            <Typography variant="body2" sx={{ color: "#6c757d" }}>
              {form.description}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  const keys = Object.keys(staticValue[0]).filter(
    (key) =>
      key !== "_id" &&
      key !== "run_id" &&
      key !== "created_At" &&
      key !== "record_type" &&
      key !== "fund_ID" &&
      key !== "company_ID" &&
      !key.includes("pageNumber")
  );
  const sortedKeys = customSortKeys(keys, extractedData, keyOrder);

  // Find the index of 'BREAK1' to split the keys
  const indexBreak1 = sortedKeys.indexOf("BREAK1");
  const indexBreak = sortedKeys.indexOf("BREAK");

  const keysBeforeBreak1 =
    indexBreak1 !== -1 ? sortedKeys.slice(0, indexBreak1) : sortedKeys;
  const keysBetweenBreak1AndBreak =
    indexBreak1 !== -1 && indexBreak !== -1
      ? sortedKeys.slice(indexBreak1 + 1, indexBreak)
      : [];
  const keysAfterBreak =
    indexBreak !== -1 ? sortedKeys.slice(indexBreak + 1) : [];

  return (
    <div
    style={{
      overflow: "visible",
      position: "relative",
    }}>
    {/* <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 1000,
        backgroundColor: "#fff",
        borderBottom: "1px solid #ddd",
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        sx={{
          "& .MuiTab-root": {
            fontWeight: "bold",
            textTransform: "none",
          },
        }}
      >
        <Tab label="Portfolio Review Key Set" />
        <Tab label="Manual Key Set" />
      </Tabs>
    </Box> */}

    <form ref={form} onSubmit={(e) => e.preventDefault()}>
      <div>
        {renderSectionHeader("Portfolio Review Key Set", "portfolio")}
        <table
          style={{
            textAlign: "left",
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  ...styles.tableLabel,
                  width: "20%",
                  textAlign: "left",
                }}
              >
                Key name
              </th>
              <th
                style={{
                  ...styles.tableLabel,
                  textAlign: "center",
                  width: "25%",
                }}
              >
                Update value manually
              </th>
              <th
                style={{
                  ...styles.tableLabel,
                  textAlign: "center",
                  width: "20%",
                }}
              >
                AI extracted value
              </th>
              <th
                style={{
                  ...styles.tableLabel,
                  textAlign: "center",
                  width: "10%",
                }}
              >
                Pages
              </th>
              <th
                style={{
                  ...styles.tableLabel,
                  color: "grey",
                  textAlign: "center",
                  width: "25%",
                }}
              >
                Previous Entry
              </th>
            </tr>
          </thead>
          <tbody>
            {keysBeforeBreak1.map((key, index) => renderRow(key, index))}
          </tbody>
        </table>
      </div>
      <div
        style={{
          marginTop: "20px",
        }}
      >
        {renderSectionHeader("Manual Key Set", "manual")}
        <table
          style={{
            textAlign: "left",
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  ...styles.tableLabel,
                  width: "20%",
                  textAlign: "left",
                }}
              >
                Key name
              </th>
              <th
                style={{
                  ...styles.tableLabel,
                  textAlign: "center",
                  width: "25%",
                }}
              >
                Update value manually
              </th>
              <th
                style={{
                  ...styles.tableLabel,
                  textAlign: "center",
                  width: "20%",
                }}
              >
                AI extracted value
              </th>
              <th
                style={{
                  ...styles.tableLabel,
                  textAlign: "center",
                  width: "10%",
                }}
              >
                Pages
              </th>
              <th
                style={{
                  ...styles.tableLabel,
                  color: "grey",
                  textAlign: "center",
                  width: "25%",
                }}
              >
                Previous Entry
              </th>
            </tr>
          </thead>
          <tbody>
            {keysBetweenBreak1AndBreak.map((key, index) =>
              renderRow(key, index)
            )}
          </tbody>
        </table>
      </div>
      <div>
        <PdfViewerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          pdfUrl={pdf_link} // Replace with your actual PDF file URL
          pageNumber={pageNumber}
        />
      </div>
      <FormLinks />
    </form>
  </ div>

  );
};

export default PortfolioForm;
