import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import React, { FC, useEffect, useRef, useState } from "react";
import axiosInstance from "../../../../utils/axios/axios.instance";
import { convertToDateFormat } from "../../../../utils/dateUtil";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Modal from "../../../../components/Workflow/ModalForAIExtraction";
import { toast } from "react-toastify";
import { useGlobalState } from "../../../../contexts/GlobalStateContext";

const AddDistributionFileForm: FC<{
  isTool?: boolean;
  handleNext?: () => void;
}> = ({ isTool, handleNext }) => {
  const { user } = useAuth0();
  const navigate = useNavigate();

  // const [fundName, setFundName] = useState("");
  const [fundID, setFundID] = useState("");
  const [fundNames, setFundNames] = useState([]);
  const [date, setDate] = useState<Date | null>(new Date());
  const [file, setFile] = useState<File | null>(null);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [userEmail, setUserEmail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [data, setData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fundKeys, setFundKeys] = useState<{ [key: string]: any }>({});
  const [selectedFundName, setSelectedFundName] = useState("");
  const { lpName } = useGlobalState();

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (
      event.dataTransfer.items &&
      event.dataTransfer.items[0].kind === "file"
    ) {
      const droppedFile = event.dataTransfer.items[0].getAsFile();
      setFile(droppedFile);
    }
  };
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
      setFileName(event.target.files[0].name.slice(0, -4));
    }
  };
  const resetData = () => {
    setData("");
    setIsButtonDisabled(false);
    setFile(null);
  };
  const getFundNames = async () => {
    const response = await axiosInstance.get(`/fund-names/${lpName}`);
    const responseData = response.data.data;
    responseData.sort((a: { Fund_Name: string }, b: { Fund_Name: string }) => {
      return a.Fund_Name.toLowerCase().localeCompare(b.Fund_Name.toLowerCase());
    });
    setFundNames(responseData);
    setFundID(responseData[0]["fund_ID"]);
    let keys: { [key: string]: any } = {};
    responseData.map((fund: { [x: string]: any }) => {
      keys[fund["fund_ID"]] = fund["Fund_Name"];
      return null; // Add a return statement here
    });
    setSelectedFundName(responseData[0]["Fund_Name"]);

    setFundKeys(keys);
  };

  useEffect(() => {
    if (user?.email) {
      setUserEmail(user.email);
    }
  }, []);
  useEffect(() => {
    getFundNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpName]);
  const handleFundChange = (event: any) => {
    setFundID(event.target.value);
    setSelectedFundName(fundKeys[event.target.value]);
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const uploadAnother = () => {
    resetData();
    handleCloseModal();
  };
  const moveToWorkflow = () => {
    navigate("/workFlow");
    handleCloseModal();
  };
  const moveToNextPage = () => {
    if (isTool && handleNext) {
      handleNext();
    } else {
      navigate("/AI_DistributionCall_Update");
    }

    handleCloseModal();
  };
  const runscript = async () => {
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 10000);
    if (file) {
      let time = new Date().toISOString();
      time = time.replace(/:/g, "-");
      try {
        toast.info("Processing the file. Please wait...");
        const formData = new FormData();
        const name = file.name.slice(0, -4);
        formData.append("pdf", file); // 'pdf' is the name the backend will use to retrieve this file
        formData.append("timestamp", time);
        formData.append("fund_ID", fundID);
        formData.append("file_name", name);
        formData.append("email", userEmail as string);
        formData.append("date", convertToDateFormat(date as Date));
        handleOpenModal();

        const response = await axiosInstance.post(
          "/AIDistribtionExtraction",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
            params: {
              timestamp: time,
              name: name,
            },
          }
        );
        setData(response.data);
      } catch (err) {
        console.error(err);
      }
    } else {
      toast.error("No file selected.");
      console.error("No file selected.");
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignSelf: "centers",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            width: "50%",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{
                  padding: "10px",
                }}
              >
                Select fund name:
              </Typography>
              <FormControl fullWidth>
                <InputLabel>Funds</InputLabel>
                <Select
                  value={fundID}
                  style={{ maxWidth: "300px" }}
                  onChange={handleFundChange}
                  label="Funds"
                >
                  {fundNames.map((fund) => (
                    <MenuItem key={fund["Fund_Name"]} value={fund["fund_ID"]}>
                      {fund["Fund_Name"]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={1}></Grid>
            <Grid item xs={12} sm={5}>
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{
                  padding: "10px",
                }}
              >
                Select Distribution Date:
              </Typography>
              <Grid
                container
                spacing={2}
                style={{
                  marginTop: "1px",
                }}
              >
                <DatePicker
                  label="Distribution Date"
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue);
                  }}
                />
                {/* <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Quarter</InputLabel>
                    <Select
                      value={selectedQuarter}
                      onChange={handleQuarterChange}
                      label="Quarter"
                    >
                      {quarters.map((quarter) => (
                        <MenuItem key={quarter.name} value={quarter.value}>
                          {quarter.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}

                {/* Year Selector */}
                {/* <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Year</InputLabel>
                    <Select
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300, // Adjust max height to your preference
                          },
                        },
                      }}
                      value={selectedYear}
                      onChange={handleYearChange}
                      label="Year"
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginTop: "20px",
                border: "2px dashed #007BFF",
                padding: "30px",
                cursor: "pointer",
                textAlign: "center",
                backgroundColor: "#ffffff",
                borderRadius: "8px",
              }}
              onDragOver={(event) => event.preventDefault()}
              onDrop={onDrop}
              onClick={() => inputFileRef.current?.click()}
            >
              <Typography variant="body1" style={{ color: "#007BFF" }}>
                {file ? file.name : "Drag & Drop or Click to Select a File"}
              </Typography>
              <input
                accept="application/pdf"
                type="file"
                style={{ display: "none" }}
                onChange={onFileChange}
                onClick={(e: any) => (e.target.value = "")}
                ref={inputFileRef}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginTop: "20px",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              <TextField
                fullWidth
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                type="email"
                label="Email Address"
                variant="outlined"
                placeholder="email@example.com"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button
                disabled={isButtonDisabled}
                onClick={runscript}
                style={{
                  backgroundColor: isButtonDisabled ? "#9E9E9E" : "#007bff",
                  color: "white",
                  border: "none",
                  padding: "12px 24px",
                  borderRadius: "18px",
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  cursor: isButtonDisabled ? "default" : "pointer",
                  outline: "none",
                  marginRight: "10px", // Add some space between the buttons
                }}
              >
                Process
              </button>
              <button
                onClick={resetData}
                style={{
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  padding: "12px 24px",
                  borderRadius: "18px",
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  cursor: "pointer",
                  outline: "none",
                }}
              >
                Reset
              </button>
            </div>

            <div
              style={{
                alignContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                marginBottom: "10%",
              }}
            >
              <div style={{ paddingTop: "10px", marginRight: "4%" }}>
                {data}
              </div>
              {data ===
                "Unfortunately we cannot process encrypted files at this time. Please come back in a few weeks for the next release" && (
                <button
                  onClick={resetData}
                  style={{
                    marginLeft: "40%",
                    marginTop: "20px",
                    backgroundColor: "#D32F2F",
                    color: "white",
                    border: "none",
                    padding: "12px 24px",
                    borderRadius: "18px",
                    fontSize: "16px",
                    fontWeight: "500",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    cursor: "pointer",
                    outline: "none",
                  }}
                >
                  Reset
                </button>
              )}
            </div>
          </div>
          <Modal
            email={userEmail}
            handleClose={uploadAnother}
            fundName={selectedFundName}
            fileName={fileName}
            show={showModal}
            handleUploadAnother={uploadAnother}
            handleMoveToWorkflow={moveToWorkflow}
            handleNextPage={moveToNextPage}
            isPortfolio={true}
            isTool={isTool}
          />
        </div>
      </div>
    </>
  );
};

export default AddDistributionFileForm;
