import React, { useState } from "react";
import {
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Link,
  Paper,
  Grid,
  TextField,
  Button,
  Box,
  CircularProgress,
  Alert,
  IconButton,
  Pagination,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import axiosInstance from "../../../utils/axios/axios.instance";
import { toast } from "react-toastify";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const DataFinder: React.FC = () => {
  const [filter, setFilter] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [expanded, setExpanded] = useState(false);
  const [hasSearched, setHasSearched] = useState<boolean>(false); 

  const entriesPerPage = 20;

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    setResults([]);
    setCurrentPage(1);
    setHasSearched(true);

    try {
      const response = await axiosInstance.post("/fund-by-query", {
        naturalLanguageQuery: filter,
      });

      const { data } = response;
      if (data.status && Array.isArray(data.data)) {
        if (data.data.length === 0) {
          toast.info("No results found. Please refine your query.");
        }
        setResults(data.data);
      } else {
        throw new Error(data?.error || "Unexpected response format.");
      }
    } catch (err: any) {
      console.error("Error during search:", err);
      const message =
        err.response?.data?.error ||
        err.message ||
        "An unexpected error occurred.";
      setError(message);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  // Helper to filter out keys with empty, null, or zero values
  const filterKeys = (obj: any) => {
    return Object.entries(obj).filter(
      ([key, value]) =>
        value !== "" && value !== null && value !== 0 && value !== "0"
    );
  };

  const totalPages = Math.ceil(results.length / entriesPerPage);
  const paginatedResults = results.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  return (
    <>
      <Helmet title="Smart Data Finder" />
      <Typography variant="h3" gutterBottom display="inline">
        Smart Data Finder
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>Smart Data Finder</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      <Paper>
        <Box
          sx={{
            backgroundColor: "#ffffff",
            padding: 3,
            borderRadius: 1,
            boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={11}>
              <TextField
                label="Enter Natural Language Query"
                variant="outlined"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: 1,
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSearch}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Search"
                )}
              </Button>
            </Grid>
          </Grid>

          {/* {error && (
            <Box mt={2}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )} */}

          {!loading && hasSearched && results.length === 0 && !error && (
            <Box mt={4} textAlign="center">
              <Typography variant="body1" color="textSecondary">
                No results to display. Please refine your query and try again.
              </Typography>
            </Box>
          )}

          {paginatedResults.length > 0 && (
            <>
              <Box
                mt={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                }}
              >
                {paginatedResults.map((item, index) => {
                  // Filter and limit keys to display
                  const filteredKeys = filterKeys(item);
                  const displayedKeys = expanded
                    ? filteredKeys
                    : filteredKeys.slice(0, 20);

                  return (
                    <Box
                      key={index}
                      sx={{
                        backgroundColor: "#f9f9f9",
                        padding: 3,
                        borderRadius: 2,
                        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                        border: "1px solid #ddd",
                      }}
                    >
                      {displayedKeys.map(([key, value]) => (
                        <Box
                          key={key}
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            marginBottom: 1.5,
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "bold",
                              marginRight: 2,
                              color: "#333",
                              flex: "0 0 20%",
                            }}
                          >
                            {key.replace(/_/g, " ")}:
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#555",
                              wordWrap: "break-word",
                              flex: "1 0 80%",
                            }}
                          >
                            {typeof value === "object" && value !== null
                              ? JSON.stringify(value, null, 2)
                              : String(value)}
                          </Typography>
                        </Box>
                      ))}
                      {filteredKeys.length > 20 && (
                        <Box textAlign="right">
                          <IconButton
                            onClick={() => setExpanded(!expanded)}
                            size="small"
                            sx={{ color: "#1976d2" }}
                          >
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>

              {/* Pagination */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 3,
                }}
              >
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(_, page) => setCurrentPage(page)}
                  color="primary"
                  size="large"
                />
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default DataFinder;
