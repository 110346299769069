import React, { ReactElement, useEffect } from "react";
import { useState } from "react";
import DashboardLayout from "../../../../layouts/Dashboard";
import { Helmet } from "react-helmet-async";
import axiosInstance from "../../../../utils/axios/axios.instance";
import {
  DistributionReport,
  fieldNames,
  orderArray,
} from "../../../../interfaces/distribution.interface";
import { Box, Button, Paper } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import DynamicForm3 from "../../../../components/Workflow/workFlow_dataUpdateForm";
import { IPortfolioQtr } from "../../../../interfaces/portfolioQtr.interface";
import { IFundStatic } from "../../../../interfaces/fundStatic.interface";
import { toast } from "react-toastify";
import ExportCSVButton from "../../../../components/ExportCSVButton";
import { useGlobalState } from "../../../../contexts/GlobalStateContext";

function Distribution() {
  const [date, setDate] = useState<Date | null>(new Date());
  const [selectedFundName, setSelectedFundName] = useState("");
  const [isPreviousData, setIsPreviosuData] = useState(false);
  const [selectedDistributionDate, setselectedDistributionDate] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [fieldData, setFieldData] = useState({});
  const [fieldData2, setFieldData2] = useState({});
  const [selectedPortfolioID, setSelectedPortfolioID] = useState("");
  const [selectedFundId, setSelectedFundId] = useState("");
  const { lpName } = useGlobalState();

  const [fundNames, setFundNames] = useState<Array<{ [key: string]: string }>>(
    []
  );
  const [portfolioNames, setPortfolioNames] = useState<
    Array<{ [key: string]: string }>
  >([]);
  function createFieldData(responseData: DistributionReport): {
    [key: string]: {
      type: string | Date;
      defaultValue: string | undefined | Date | File | Boolean | Number;
      name: string | undefined;
    };
  } {
    const excludedFields = ["_id", "fund_ID", "company_ID", "distribution_ID"];
    const fieldData: {
      [key: string]: {
        type: string | Date;
        defaultValue: string | undefined | Date | File | Boolean | Number;
        name: string | undefined;
      };
    } = {};

    orderArray.forEach((key) => {
      if (excludedFields.includes(key) || !(key in responseData)) {
        return;
      }
      let value;
      if (key === "Fund_Name") {
        value = responseData[key as keyof DistributionReport];
      } else {
        value = responseData[key as keyof DistributionReport];
      }

      // const value = responseData[key as keyof DistributionReport];
      let type: string;

      if (typeof value === "string") {
        type = "text";
      } else if (typeof value === "boolean") {
        type = "checkbox";
      } else if (typeof value === "number") {
        type = "number";
      } else if (Array.isArray(value)) {
        type = "array";
      } else {
        type = "string";
      }

      fieldData[key] = {
        type,
        defaultValue: value,
        name: fieldNames?.[key as keyof DistributionReport],
      };
    });
    return fieldData;
  }
  const fetchData = async (id: string, id2: string) => {
    try {
      setFieldData({});
      const response = await axiosInstance.get(
        `/getDistributionByID/${id}/${id2}`
      );
      const response2 = await axiosInstance.get(
        `/getDistributionCurrentData/${id}/${id2}`
      );
      if (response.data === "error") {
        toast.error("Error while fetching Data.");
      } else {
        if (response.data.data.length === 0) {
          toast.error("There is no data with the given Date");
        } else {
          const responseData = response.data.data[0];
          const responseData2 = response2.data.data[0];
          if (responseData2 !== undefined) {
            setIsPreviosuData(true);
            setFieldData2(createFieldData(responseData2));
          } else {
            setIsPreviosuData(false);
            setFieldData2(
              createFieldData({
                _id: "",
                Fund_Name: selectedFundName,
                distribution_date: id,
                distribution_capitalCost: 0,
                distribution_gainLoss: 0,
                distribution_other: 0,
                distribution_distofCapital: 0,
                distribution_interest: 0,
                distribution_dividends: 0,
                distribution_total: 0,
                fund_ID: selectedFundId,
                distribution_ID: "",
                distribution_LPname_total: 0,
                LP_name: "",
              })
            );
          }
          setIsSelected(true);
          setFieldData(createFieldData(responseData));
        }
      }
    } catch (err) {
      toast.error("Error while fetching Data.");
      console.error(err);
    }
  };
  function formatDate(dateString: Date) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // +1 because getMonth() returns 0-11
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  const handleChangeData = async (formData: {
    [fieldName: string]: string | undefined | Date | File | Boolean;
  }) => {
    try {
      const form = new FormData();
      if (isPreviousData) {
        form.append("formData", JSON.stringify(formData));
        form.append("company_ID", selectedPortfolioID);
        form.append("distribution_date", selectedDistributionDate);
        const response = await axiosInstance.patch(
          "/updateDistributionByID",
          form,
          {}
        );
        if (response.data === "error") {
          toast.error("Error while updating Data.");
        } else {
          setFieldData({});
          toast.success("Data updated successfully");
        }
      } else {
        formData.company_ID = selectedPortfolioID;
        formData.distribution_date = selectedDistributionDate;
        formData.fund_ID = selectedFundId;
        formData.distribution_ID = "";
        form.append("formData", JSON.stringify(formData));
        const response = await axiosInstance.post("/addDistributionData", form);
        if (response.data === "error") {
          toast.error("Error while updating Data.");
        } else {
          setFieldData({});
          toast.success("Data updated successfully");
        }
      }

      // Assuming you have the Base64 encoded ZIP string in a variable called `zipString`
    } catch (err) {
      toast.error("Error while updating Data.");
    } finally {
      setTimeout(() => {
        fetchData(selectedDistributionDate, selectedPortfolioID);
      }, 3000);
    }
  };
  const getPortfolioNames = async (id: string) => {
    try {
      const response = await axiosInstance.get("/getCompanies/" + id);
      const responseData = response.data.data;
      responseData.sort(
        (
          a: { portfolio_companyName: string },
          b: { portfolio_companyName: string }
        ) => {
          return a.portfolio_companyName
            .toLowerCase()
            .localeCompare(b.portfolio_companyName.toLowerCase());
        }
      );
      const portfolioNames = responseData.map((portfolio: IPortfolioQtr) => {
        return {
          [portfolio.portfolio_companyName as string]: portfolio.company_ID,
        };
      });
      setPortfolioNames(portfolioNames);
      setSelectedPortfolioID(Object.values(portfolioNames[0])[0] as string);
    } catch (error) {
      console.error(error);
    }
  };
  const getFundNames = async () => {
    try {
      const response = await axiosInstance.get("/fund-names/" + lpName);
      const responseData = response.data.data;
      responseData.sort(
        (a: { Fund_Name: string }, b: { Fund_Name: string }) => {
          return a.Fund_Name.toLowerCase().localeCompare(
            b.Fund_Name.toLowerCase()
          );
        }
      );
      const fundNames = responseData.map((fund: IFundStatic) => {
        return { [fund.Fund_Name]: fund.fund_ID };
      });
      setFundNames(fundNames);
      getPortfolioNames(Object.values(fundNames[0])[0] as string);
      setSelectedFundName(Object.keys(fundNames[0])[0] as string);
      setSelectedFundId(Object.values(fundNames[0])[0] as string);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getFundNames();
    // getPortfolioNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpName]);

  useEffect(() => {
    setselectedDistributionDate(formatDate(new Date()));
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Add Reporting Data" />
      <Paper
        style={{
          padding: "10px",
        }}
      >
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h1>Distribution Form</h1>
            <ExportCSVButton collection="distributions" />
          </div>
          <Box
            display="flex"
            // justifyContent="space-between"
            alignItems="center"
            padding="10px"
          >
            <div>
              <label
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  fontWeight: "bold",
                }}
              >
                Fund Name:{" "}
              </label>
              <select
                onChange={(e) => {
                  setSelectedFundName(
                    Object.keys(fundNames[e.target.selectedIndex]).toString()
                  );
                  setSelectedFundId(e.target.value);
                  getPortfolioNames(e.target.value);
                }}
                style={{
                  backgroundColor: "#f2f2f2", // Background color
                  color: "#333", // Text color
                  padding: "10px", // Padding
                  border: "1px solid #ccc", // Border
                  borderRadius: "5px", // Border radius
                  outline: "none", // Remove outline when selected
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                  width: "200px", // Width
                  cursor: "pointer", // Cursor style
                  height: "52px",
                  marginTop: "20px",
                }}
              >
                {fundNames.map((fund, index) => {
                  const fundName = Object.keys(fund)[0];
                  const fundID = fund[fundName];
                  return (
                    <option key={index} value={fundID}>
                      {fundName}
                    </option>
                  );
                })}
              </select>
              <label
                style={{
                  marginLeft: "20px",
                  marginTop: "10px",
                  fontWeight: "bold",
                }}
              >
                Portfolio Name:{" "}
              </label>
              <select
                // ref={portfolioID}
                onChange={(e) => {
                  setSelectedPortfolioID(e.target.value);
                  // setSelectedPortfolioID(portfolioID.current?.value ?? "");
                }}
                style={{
                  backgroundColor: "#f2f2f2", // Background color
                  color: "#333", // Text color
                  padding: "10px", // Padding
                  border: "1px solid #ccc", // Border
                  borderRadius: "5px", // Border radius
                  outline: "none", // Remove outline when selected
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                  width: "200px", // Width
                  cursor: "pointer", // Cursor style
                  height: "52px",
                }}
              >
                {portfolioNames.map((portfolio, index) => {
                  const portfolioName = Object.keys(portfolio)[0];
                  const company_ID = portfolio[portfolioName];
                  return (
                    <option key={index} value={company_ID}>
                      {portfolioName}
                    </option>
                  );
                })}
              </select>
              {/* <label
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  fontWeight: "bold",
                }}
              >
                Fund Name:{" "}
              </label>
              <select
                id="fund-name-select"
                onChange={(e) => {
                  setSelectedFundName(e.target.value);
                  fetchData(selectedDistributionDate, e.target.value);
                }}
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "#333",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  outline: "none",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                  width: "200px",
                  cursor: "pointer",
                }}
              >
                {fundName.map((fund, index) => (
                  <option key={index} value={fund}>
                    {fund}
                  </option>
                ))}
              </select> */}
            </div>
            <div
              style={{
                marginLeft: "20px",
                marginTop: "20px",
                display: "flex",
              }}
            >
              <div style={{ paddingTop: "15px" }}>
                <label
                  style={{
                    marginRight: "10px",
                    marginTop: "40px",
                    fontWeight: "bold",
                  }}
                >
                  Select a date :
                </label>
              </div>
              <div>
                <DatePicker
                  format="dd-MM-yyyy"
                  label="Transaction Date"
                  value={date}
                  onChange={(newValue) => {
                    if (newValue !== null) {
                      // fetchData(formatDate(newValue), selectedFundName);
                      setselectedDistributionDate(formatDate(newValue));
                      setDate(newValue);
                    }
                  }}
                />
              </div>
            </div>
          </Box>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                marginLeft: "10px",
                marginTop: "10px",
                padding: "12px 24px",
                backgroundColor: "#007bff",
                color: "#fff",
                border: "none",
                borderRadius: "18px",
                cursor: "pointer",
                fontWeight: "bold",
                height: "48px",
              }}
              variant="contained"
              color="primary"
              onClick={() =>
                fetchData(selectedDistributionDate, selectedPortfolioID)
              }
            >
              Get Data
            </Button>
          </div>
          {isSelected && (
            <DynamicForm3
              fieldData={fieldData}
              changedata={handleChangeData}
              staticData={false}
              date={selectedDistributionDate}
              fieldData2={fieldData2}
              isUpdate={isPreviousData}
            />
          )}
          {/* {message !== "" && <>{message}</>} */}
        </div>
      </Paper>
    </React.Fragment>
  );
}
Distribution.getLayout = function getLayout(page: ReactElement) {
  return <DashboardLayout>{page}</DashboardLayout>;
};
export default Distribution;
