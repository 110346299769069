import React, { useState } from "react";
import TruncateText from "./dialog";
import IconButton from "@mui/material/IconButton";
import { Box, TextField, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PdfViewerModal from "../../add_updateForm/PDFModal";
import LinkIcon from "@mui/icons-material/Link";

interface FundFormTableProps {
  form: React.RefObject<HTMLFormElement>;
  fundStaticData: any[];
  fundData: any[];
  currentRunID: string | null;
  keyDisplayNames: { [key: string]: string };
  fundInputValue: { [key: string]: any };
  handleInputChange: (key: string, value: any) => void;
  constKey: string[];
  pdf_link: string;
  FundkeyOrder: string[];
  styles: { [key: string]: React.CSSProperties };
}

const FundFormTable: React.FC<FundFormTableProps> = ({
  form,
  pdf_link,
  fundStaticData,
  fundData,
  currentRunID,
  keyDisplayNames,
  fundInputValue,
  handleInputChange,
  constKey,
  FundkeyOrder,
  styles,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const customSortKeys = (
    keys: string[],
    extractedData: Record<string, any> | undefined,
    keyOrder: string[]
  ) => {
    // Ensure extractedData is defined
    const safeExtractedData = extractedData || {};

    const keysWithoutBreaks = keys.filter(
      (key) => key !== "BREAK1" && key !== "BREAK"
    );

    const beforeBreak1 = keysWithoutBreaks.filter(
      (key) =>
        keyOrder.indexOf(key) !== -1 &&
        keyOrder.indexOf(key) < keyOrder.indexOf("BREAK1")
    );

    const betweenBreak1AndBreak = keysWithoutBreaks.filter(
      (key) =>
        keyOrder.indexOf(key) !== -1 &&
        keyOrder.indexOf(key) >= keyOrder.indexOf("BREAK1") &&
        keyOrder.indexOf(key) < keyOrder.indexOf("BREAK")
    );

    const afterBreak = keysWithoutBreaks.filter(
      (key) =>
        keyOrder.indexOf(key) !== -1 &&
        keyOrder.indexOf(key) >= keyOrder.indexOf("BREAK")
    );

    const sortSection = (sectionKeys: string[]) => {
      return sectionKeys.sort((a, b) => {
        const hasValueA = safeExtractedData[a] ? 1 : 0;
        const hasValueB = safeExtractedData[b] ? 1 : 0;

        // If one has a value and the other doesn't, prioritize the one with a value
        if (hasValueA !== hasValueB) {
          return hasValueB - hasValueA;
        }

        // If both have the same status, sort by keyOrder
        return keyOrder.indexOf(a) - keyOrder.indexOf(b);
      });
    };

    const sortedBeforeBreak1 = sortSection(beforeBreak1);
    const sortedBetweenBreak1AndBreak = sortSection(betweenBreak1AndBreak);
    const sortedAfterBreak = sortSection(afterBreak);

    return [
      ...sortedBeforeBreak1,
      "BREAK1",
      ...sortedBetweenBreak1AndBreak,
      "BREAK",
      ...sortedAfterBreak,
    ];
  };

  const renderSectionHeader = (title: string, ismargin: boolean) => (
    <>
      <tr>
        <td colSpan={5}>
          <div
            style={{
              marginTop: ismargin ? "20px" : "0",
              padding: "15px 0",
              borderBottom: "4px solid #2E3A49",
              backgroundColor: "#E1E8EE",
              textAlign: "center",
            }}
          >
            <h3
              style={{
                margin: 0,
                color: "#1B2430",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              {title}
            </h3>
          </div>
        </td>
      </tr>
      <tr
        style={{
          backgroundColor: "#F3F6FA",
          borderBottom: "2px solid #2E3A49",
        }}
      >
        <th style={{ ...styles.tableLabel, width: "20%", textAlign: "left" }}>
          Key name
        </th>
        <th
          style={{
            ...styles.tableLabel,
            textAlign: "center",
            width: "25%",
          }}
        >
          Update value manually
        </th>
        <th
          style={{
            ...styles.tableLabel,
            textAlign: "center",
            width: "20%",
          }}
        >
          AI extracted value
        </th>
        <th
          style={{
            ...styles.tableLabel,
            textAlign: "center",
            width: "10%",
          }}
        >
          Pages
        </th>
        <th
          style={{
            ...styles.tableLabel,
            color: "grey",
            textAlign: "center",
            width: "25%",
          }}
        >
          Previous Entry
        </th>
      </tr>
      <tr>
        <td colSpan={5} style={{ height: "10px" }}></td> {/* Spacer row */}
      </tr>
    </>
  );
  const FormLinks = () => {
    const forms = [
      {
        title: "Edit Static Information",
        description:
          "Navigate to Static Key Set Page to view or update static keys.",
        url: "/add_data",
      },
      // {
      //   title: "Edit Key Metrics",
      //   description:
      //     "Navigate to Key Metrics Page to update or add Key Metrics for this portoflio.",
      //   url: "/update_PortfolioYearlyTable",
      // },
      // {
      //   title: "Edit Investment Valuation",
      //   description:
      //     "Navigate to Investment History Page to update or add Valuation for this portfolio.",
      //   url: "/update_PortfolioReviewTable",
      // },
    ];

    return (
      <Box
        sx={{
          marginTop: "30px",
          display: "flex",
          flexWrap: "wrap",
          gap: "16px",
          justifyContent: "space-evenly",
        }}
      >
        {forms.map((form, index) => (
          <Box
            key={index}
            sx={{
              backgroundColor: "#f8f9fa",
              borderRadius: "8px",
              padding: "16px",
              maxWidth: "350px",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              transition: "transform 0.2s ease",
              "&:hover": {
                transform: "translateY(-3px)",
                boxShadow: "0 6px 14px rgba(0,0,0,0.15)",
              },
              cursor: "pointer",
            }}
            onClick={() => window.open(form.url, "_blank")}
          >
            <LinkIcon
              sx={{ fontSize: "48px", color: "#007bff", marginBottom: "8px" }}
            />
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "#343a40" }}
            >
              {form.title}
            </Typography>
            <Typography variant="body2" sx={{ color: "#6c757d" }}>
              {form.description}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <form
      ref={form}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div>
        <table
          style={{
            textAlign: "left",
            width: "100%", // White background
            borderCollapse: "separate", // Allows the borders to be separated for spacing
            borderSpacing: "1px", // Sets the space between the cells
          }}
        >
          {renderSectionHeader("Dynamic Key Set", false)}
          <tbody>
            {customSortKeys(
              Object.keys(fundStaticData[0]).filter(
                (key) =>
                  key !== "_id" &&
                  key !== "created_At" &&
                  key !== "run_id" &&
                  key !== "record_type" &&
                  key !== "fund_ID" &&
                  !key.includes("pageNumber")
              ),
              fundData[0],
              FundkeyOrder
            ).map((key, index) => {
              const file = currentRunID
                ? (fundData[0] as { [key: string]: any })
                : {};
              const reference = fundStaticData[0] as { [key: string]: any };
              if (key === "_id") return null;
              if (key === "BREAK" || key === "BREAK1") {
                return key === "BREAK" ? (
                  <></>
                ) : (
                  renderSectionHeader("Manual Key Set", true)
                );
              } else {
                let backgroundColor = "#FFFFFF";
                if (
                  FundkeyOrder.indexOf(key) < FundkeyOrder.indexOf("BREAK1")
                ) {
                  backgroundColor = "rgba(175, 211, 227, 0.2)";
                } else if (
                  FundkeyOrder.indexOf(key) >= FundkeyOrder.indexOf("BREAK1") &&
                  FundkeyOrder.indexOf(key) < FundkeyOrder.indexOf("BREAK")
                ) {
                  backgroundColor = "rgba(184, 224, 199, 0.2)";
                } else if (
                  FundkeyOrder.indexOf(key) >= FundkeyOrder.indexOf("BREAK")
                ) {
                  backgroundColor = "#F2F2F2";
                }
                return (
                  <tr
                    key={index}
                    style={{
                      paddingTop: "30px",
                      borderBottom: "1px solid #cccccc",
                      backgroundColor:
                        index % 2 === 0 ? backgroundColor : "#FFFFFF",
                    }}
                  >
                    <td
                      style={{
                        ...styles.label,
                        paddingBottom: "10px",
                        width: "20%",
                      }}
                    >
                      {keyDisplayNames[key] || key.replace(/_/g, " ")}
                    </td>
                    <td
                      style={{
                        padding: "5px",
                      }}
                    >
                      <TextField
                        key={key}
                        name={key}
                        id={index.toString()}
                        multiline
                        minRows={1}
                        maxRows={6}
                        style={{
                          width: "100%", // Make input take full width of its cell
                          textAlign: "justify",
                          marginBottom: "10px",
                          background: "#fff", // Adds a white background
                          borderColor: "#007bff", // Blue border color
                          borderWidth: "2px", // Border width
                          borderRadius: "4px", // Rounded corners
                        }}
                        value={fundInputValue[key]}
                        type="text"
                        onChange={(e: { target: { value: any } }) => {
                          handleInputChange(key, e.target.value);
                        }}
                        disabled={constKey.includes(key)}
                      />
                    </td>

                    {file &&
                    (typeof file[key] === "string" ||
                      typeof file[key] === "number") &&
                    file[key] !== "" ? (
                      <>
                        <td
                          style={{
                            ...styles.label,
                            paddingBottom: "10px",
                            textAlign: "center",
                            width: "25%", // Set width to 25%
                          }}
                        >
                          <TruncateText text={file[key]} maxWords={40} />
                          {!constKey.includes(key) && (
                            <IconButton
                              aria-label="content copy"
                              size="small"
                              onClick={() => handleInputChange(key, file[key])}
                            >
                              <ContentCopyIcon fontSize="inherit" />
                            </IconButton>
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            width: "10% !important", // Set width to 25%
                          }}
                          onClick={() => {
                            if (fundData[0].page && key in fundData[0].page) {
                              setPageNumber(fundData[0].page[key]);
                              window.open(
                                `${pdf_link}#page=${fundData[0].page[key]}&toolbar=0`,
                                "_blank"
                              );
                            }
                          }}
                        >
                          {fundData[0].page && key in fundData[0].page ? (
                            <p
                              style={{
                                cursor: "pointer",
                                color: "blue",
                                textDecoration: "underline",
                              }}
                            >
                              {fundData[0].page[key]}
                            </p>
                          ) : (
                            <p>-</p>
                          )}
                        </td>
                      </>
                    ) : (
                      <>
                        <td
                          style={{
                            textAlign: "center",
                            width: "10% !important", // Set width to 25%
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            width: "10% !important", // Set width to 25%
                          }}
                        >
                          -
                        </td>
                      </>
                    )}

                    <td
                      style={{
                        ...styles.label,
                        paddingBottom: "10px",
                        textAlign: "center",
                        color: "grey",
                        width: "25%", // Set width to 25%
                      }}
                    >
                      {reference[key] === "" ? (
                        "-"
                      ) : (
                        <TruncateText text={reference[key]} maxWords={40} />
                      )}
                      {reference[key] !== "" && !constKey.includes(key) && (
                        <IconButton
                          aria-label="content copy"
                          size="small"
                          onClick={() => handleInputChange(key, reference[key])}
                        >
                          <ContentCopyIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
      <FormLinks />

      <div>
        <PdfViewerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          pdfUrl={pdf_link} // Replace with your actual PDF file URL
          pageNumber={pageNumber}
        />
      </div>
    </form>
  );
};

export default FundFormTable;
