import { FilePlus, Tool } from "react-feather";
import { SidebarItemsType } from "../../types/sidebar";
import { ExcelSheetSVG, OnboardingSVG, WorkflowSVG } from "../Svgs";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
const pagesSection = [
  {
    href: "/pdf_extract",
    icon: Tool,
    title: "Tools",
  },
  // {
  //   href: "/add_pdf",
  //   icon: CheckSquare,
  //   title: "Add PDF",
  // },
  // {
  //   href: "/AI_extract",
  //   icon: CheckSquare,
  //   title: "PDF AI Extraction",
  // },

  // {
  //   href: "/update_script_data",
  //   icon: CheckSquare,
  //   title: "AI Data Updation",
  // },

  {
    href: "/add_data",
    icon: FilePlus,
    title: "Add/Update Data",
  },
  // {
  //   href: "/update_fundRaising",
  //   icon: CheckSquare,
  //   title: "Update FundRaising",
  // },
  // {
  //   href: "/Update_AIData",
  //   icon: CheckSquare,
  //   title: "Update AI Dashboard",
  // },
  // {
  //   href: "/Update_ESG",
  //   icon: CheckSquare,
  //   title: "Update ESG Reports",
  // },
  {
    href: "/workFlow",
    icon: WorkflowSVG,
    title: "WorkFlow",
  },
  {
    href: "/Fund_onboarding",
    icon: OnboardingSVG,
    title: "Fund Onboarding",
  },
  {
    href: "/Run_Statistics",
    icon: DoneAllIcon,
    title: "Run Status",
  },
  {
    href: "/Smart_Data_Finder",
    icon: AutoAwesomeIcon,
    title: "Smart Data Finder",
  },
  // {
  //   href: "/Update_FOF",
  //   icon: CheckSquare,
  //   title: "FOF Reports",
  // },
  // {
  //   href: "/update_Distribution",
  //   icon: CheckSquare,
  //   title: "Distribution Form",
  // },
  // {
  //   href: "/update_CapitalCall",
  //   icon: CheckSquare,
  //   title: "Capital Call Form",
  // },
  // {
  //   href: "/update_PortfolioReview",
  //   icon: CheckSquare,
  //   title: "Portfolio Review Form",
  // },
  // {
  //   href: "/update_marketWatch",
  //   icon: CheckSquare,
  //   title: "Market Watch Reports",
  // },
] as SidebarItemsType[];

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
];

export default navItems;
