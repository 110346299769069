import { useAuth0 } from "@auth0/auth0-react";
import {
  Paper,
  Typography,
  Divider as MuiDivider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import axiosInstance from "../../utils/axios/axios.instance";
import { toast } from "react-toastify";
import KeyMetricsTable from "./Investment_Analysis/keyMetricTable";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { extrcatedData } from "../../interfaces/keyMetricsTable";
import InfoCard from "./Info";

const Divider = styled(MuiDivider)(spacing);

function AppendixIngestion() {
  const { user } = useAuth0();
  const [file, setFile] = useState<File | null>(null);
  const [userEmail, setUserEmail] = useState("");
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [years, setYears] = useState<number[]>([]);
  const [output, setOutput] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isExecuted, setIsExecuted] = useState(false);
  const [selectedLpname, setselectedLpname] = useState("TC2009");

  const quarters = [
    { name: "Q1", value: "31-03" },
    { name: "Q2", value: "30-06" },
    { name: "Q3", value: "30-09" },
    { name: "Q4", value: "31-12" },
  ];
  const Lpname = [
    { name: "TC2009", value: "TC2009" },
    { name: "TC2014", value: "TC2014" },
    { name: "TC2018", value: "TC2018" },
    { name: "TC2019", value: "TC2019" },
    { name: "TC2023", value: "TC2023" },
  ];
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const resetData = () => {
    setFile(null);
    setIsExecuted(false);
    setOutput([]);
  };

  const generateYears = (startYear: number): number[] => {
    const currentYear = new Date().getFullYear();
    const years: number[] = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return years.reverse();
  };
  const handleQuarterChange = (event: any) => {
    const quarter = event.target.value;
    setSelectedQuarter(quarter);
  };

  const handleYearChange = (event: any) => {
    const year = event.target.value;
    setSelectedYear(year);
  };
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (
      event.dataTransfer.items &&
      event.dataTransfer.items[0].kind === "file"
    ) {
      const droppedFile = event.dataTransfer.items[0].getAsFile();
      setFile(droppedFile);
    }
  };
  const setCurrentDate = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    let currentQuarter = "";
    let currentYear = currentDate.getFullYear();

    if (currentMonth >= 1 && currentMonth <= 3) {
      currentQuarter = "31-12";
      currentYear = currentYear - 1;
    } else if (currentMonth >= 4 && currentMonth <= 6) {
      currentQuarter = "31-03";
    } else if (currentMonth >= 7 && currentMonth <= 9) {
      currentQuarter = "30-06";
    } else {
      currentQuarter = "30-09";
    }
    setSelectedQuarter(currentQuarter);
    setSelectedYear(currentYear.toString());
  };
  const handleLPChange = (event: any) => {
    setselectedLpname(event.target.value);
  };
  useEffect(() => {
    if (user?.email) {
      setUserEmail(user.email);
    }
    const dynamicYears = generateYears(2000);
    setYears(dynamicYears);
    setCurrentDate();
  }, []);

  const runscript = async () => {
    if (file) {
      setIsLoading(true);
      const formData = new FormData();
      let time = new Date().toISOString();
      time = time.replace(/:/g, "-");

      // Create a new file object with the name 'testFile'
      const newFile = new File([file], "testFile.zip", { type: file.type });

      formData.append("file", newFile); // Use the new file object here
      formData.append("LP_name", selectedLpname);
      formData.append("Reporting_Date", selectedQuarter + "-" + selectedYear);
      try {
        setIsExecuted(true);

        const response = await axiosInstance.post(`Appendix-script`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            timestamp: time,
            email: userEmail,
          },
        });

        // Handle the response if needed
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
        toast.success("Data Processed Successfully");
      }
    } else {
      toast.error("Please select a file to process");
    }
  };

  const handleDataUpdate = async (data: extrcatedData[]) => {
    let inputData = JSON.stringify({
      documents: data,
    });
    try {
      const response = await axiosInstance.post(`persistPrData`, inputData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.status) {
        toast.success("Data Updated Successfully");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        resetData();
      }, 1000);
    }
  };

  return (
    <>
      <Paper style={{ width: "100%", paddingTop: "40px" }}>
        <Helmet title="Quarterly Updates" />

        <Typography
          variant="h3"
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontWeight: "bold",
          }}
        >
          Appendix data Ingestion
        </Typography>
        <Divider my={6} />
        <InfoCard
          purpose="This tool ingests data using Appendix XLSX files."
          input="A ZIP file containing all 8 Appendix XLSX files, along with the LP name and reporting date."
          output="The data will be added to the database upon completion."
        />

        <div
          style={{
            width: "100%",
            display: "flex",
            alignContent: "center",
            alignSelf: "centers",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              maxWidth: "800px",
              width: "50%",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="subtitle2"
                  fontSize="15px"
                  style={{
                    padding: "10px",
                  }}
                >
                  Select LP name:
                </Typography>
                <FormControl fullWidth>
                  <InputLabel>Funds</InputLabel>
                  <Select
                    value={selectedLpname}
                    style={{ maxWidth: "300px" }}
                    onChange={handleLPChange}
                    label="Funds"
                  >
                    {Lpname.map((lpName) => (
                      <MenuItem key={lpName.name} value={lpName.value}>
                        {lpName.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="subtitle2"
                  fontSize="15px"
                  style={{
                    padding: "10px",
                  }}
                >
                  Select reporting quarter and year:
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Quarter</InputLabel>
                      <Select
                        value={selectedQuarter}
                        onChange={handleQuarterChange}
                        label="Quarter"
                      >
                        {quarters.map((quarter) => (
                          <MenuItem key={quarter.name} value={quarter.value}>
                            {quarter.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Year Selector */}
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Year</InputLabel>
                      <Select
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 300, // Adjust max height to your preference
                            },
                          },
                        }}
                        defaultValue={selectedYear}
                        value={selectedYear}
                        onChange={handleYearChange}
                        label="Year"
                      >
                        {years.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div
              style={{
                marginTop: "20px",
                border: "2px dashed #007BFF",
                padding: "30px",
                cursor: "pointer",
                textAlign: "center",
                backgroundColor: "#ffffff",
                borderRadius: "8px",
              }}
              onDragOver={(event) => event.preventDefault()}
              onDrop={onDrop}
              onClick={() => inputFileRef.current?.click()}
            >
              <Typography variant="body1" style={{ color: "#007BFF" }}>
                {file ? file.name : "Drag & Drop or Click to Select a File"}
              </Typography>
              <input
                accept=".zip"
                type="file"
                style={{ display: "none" }}
                onChange={onFileChange}
                onClick={(e: any) => (e.target.value = "")}
                ref={inputFileRef}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginTop: "20px",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  marginTop: "20px",
                }}
              >
                <LoadingButton
                  style={{
                    backgroundColor: "#007BFF",
                    color: "white",
                    border: "none",
                    padding: "8px 20px",
                    borderRadius: "20px",
                    fontSize: "16px",
                    fontWeight: "500",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    cursor: "pointer",
                    outline: "none",
                  }}
                  color="secondary"
                  onClick={runscript}
                  loading={isLoading}
                  endIcon={<SendIcon />}
                  loadingPosition="end"
                  variant="contained"
                >
                  <span>Process</span>
                </LoadingButton>
                {/* <button onClick={runscript}>Process</button> */}
                {isExecuted && (
                  <button
                    onClick={resetData}
                    style={{
                      marginTop: "20px",
                      backgroundColor: "#D32F2F",
                      marginLeft: "20px",
                      color: "white",
                      border: "none",
                      padding: "12px 24px",
                      borderRadius: "20px",
                      fontSize: "16px",
                      fontWeight: "500",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      transition: "transform 0.2s, box-shadow 0.2s",
                      cursor: "pointer",
                      outline: "none",
                    }}
                  >
                    Reset
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Paper>
      {output.length > 0 && (
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <KeyMetricsTable
            data={output}
            figureInspectionDate={""}
            handleDataUpdate={handleDataUpdate}
          />
        </div>
      )}
    </>
  );
}

export default AppendixIngestion;
