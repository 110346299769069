import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  padding: "50px",
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
};

interface CustomModalProps {
  open: boolean;
  handleClose: () => void;
  onUpdate: (selectedOption: string) => void;
  defaultSelectedOption: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  handleClose,
  onUpdate,
  defaultSelectedOption,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>(
    defaultSelectedOption,
  );
  const options = ["TC2009", "TC2014", "TC2018"];

  useEffect(() => {
    if (open) {
      setSelectedOption(defaultSelectedOption);
    }
  }, [open, defaultSelectedOption]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value);
  };

  const handleUpdate = () => {
    onUpdate(selectedOption);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-title" variant="h6" component="h2">
          Change Preference
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          Here you can change your preferences.
        </Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="select-label">Preference</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            value={selectedOption}
            onChange={handleChange}
            label="Preference"
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={buttonContainerStyle}>
          <Button variant="contained" color="primary" onClick={handleUpdate}>
            Update
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomModal;
