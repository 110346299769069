import { useState, ReactElement, useEffect } from "react";
import axiosInstance from "../../utils/axios/axios.instance";
import DashboardLayout from "../../layouts/Dashboard";
import {
  Paper,
  Typography,
  Divider as MuiDivider,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useAuth0 } from "@auth0/auth0-react";
import InfoCard from "./Info";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import MultipleFileUpload from "../MultiFileUpload/multifileUPload";
import DOMPurify from "dompurify";
import { sanatizeUserInput } from "../../utils/textUtils";
import { useGlobalState } from "../../contexts/GlobalStateContext";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(
  name: string,
  selectedPortfolios: readonly string[],
  theme: any
) {
  return {
    fontWeight:
      selectedPortfolios.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Divider = styled(MuiDivider)(spacing);

function PortfolioReviewTextboxesTool() {
  const theme = useTheme();
  const { user } = useAuth0();
  const [userEmail, setUserEmail] = useState("");
  const [userText, setUserText] = useState("");
  const [fundID, setfundID] = useState("");
  const [fundIDs, setfundIDs] = useState([]);
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [years, setYears] = useState<number[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [portfolioOptions, setPortfolioOptions] = useState([]);
  const [selectedPortfolios, setSelectedPortfolios] = useState<string[]>([]);
  const [showResetButton, setShowResetButton] = useState(false);
  const { lpName } = useGlobalState();

  const quarters = [
    { name: "Q1", value: "31-03" },
    { name: "Q2", value: "30-06" },
    { name: "Q3", value: "30-09" },
    { name: "Q4", value: "31-12" },
  ];

  const runscript = async () => {
    if (files.length > 0) {
      let time = new Date().toISOString().replace(/:/g, "-");
      try {
        toast.info(
          "File Processing Started. You will receive an email shortly."
        );
        setShowResetButton(true);
        const formData = new FormData();
        files.forEach((currentFile) => {
          formData.append("pdf", currentFile);
        });
        selectedPortfolios.forEach((portfolio) => {
          formData.append("company_ID", portfolio);
        });
        formData.append("timestamp", time);
        formData.append("fund_ID", fundID);
        formData.append("file_name", files.map((f) => f.name).join(", "));
        formData.append("email", userEmail);
        formData.append("UserNotes", sanatizeUserInput(userText));
        formData.append("date", `${selectedQuarter}-${selectedYear}`);

        await axiosInstance.post(
          "/PortfolioReview-script-textboxes",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
            params: { timestamp: time },
          }
        );
      } catch (err) {
        toast.error("Error Processing File. Please try again later.");
      }
    } else {
      toast.error("Please select at least one file to process");
    }
  };

  const generateYears = (startYear: number): number[] => {
    const currentYear = new Date().getFullYear();
    const years: number[] = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return years.reverse();
  };

  const handleQuarterChange = (event: any) => {
    setSelectedQuarter(event.target.value);
  };

  const handleYearChange = (event: any) => {
    setSelectedYear(event.target.value);
  };

  const handleFundChange = (event: any) => {
    setfundID(event.target.value);
    getPortfolioNames(event.target.value);
  };

  const getFundNames = async () => {
    const response = await axiosInstance.get("/fund-names/" + lpName);
    const responseData = response.data.data;
    const sortedData = responseData.sort(
      (a: { Fund_Name: string }, b: { Fund_Name: string }) => {
        return a.Fund_Name.toLowerCase().localeCompare(
          b.Fund_Name.toLowerCase()
        );
      }
    );
    setfundIDs(sortedData);
    setfundID(sortedData[0]["fund_ID"]);
    getPortfolioNames(sortedData[0]["fund_ID"]);
    let keys: { [key: string]: any } = {};
    sortedData.map((fund: { [x: string]: any }) => {
      keys[fund["fund_ID"]] = fund["Fund_Name"];
    });
  };
  const getPortfolioNames = async (fund_ID: string = "") => {
    try {
      const response = await axiosInstance.get("/getCompanies/" + fund_ID);
      const responseData = response.data.data;
      const sortedPortfolios = responseData.sort(
        (
          a: { portfolio_companyName: string },
          b: { portfolio_companyName: string }
        ) => {
          return a.portfolio_companyName
            .toLowerCase()
            .localeCompare(b.portfolio_companyName.toLowerCase());
        }
      );
      setPortfolioOptions(sortedPortfolios);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user?.email) {
      setUserEmail(user.email);
    }
    const dynamicYears = generateYears(2000);
    setYears(dynamicYears);
    setCurrentDate();
  }, [user]);
  useEffect(() => {
    getFundNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpName]);
  const setCurrentDate = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    let currentQuarter = "";
    let currentYear = currentDate.getFullYear();

    if (currentMonth >= 1 && currentMonth <= 3) {
      currentQuarter = "31-12";
      currentYear = currentYear - 1;
    } else if (currentMonth >= 4 && currentMonth <= 6) {
      currentQuarter = "31-03";
    } else if (currentMonth >= 7 && currentMonth <= 9) {
      currentQuarter = "30-06";
    } else {
      currentQuarter = "30-09";
    }
    setSelectedQuarter(currentQuarter);
    setSelectedYear(currentYear.toString());
  };

  const handlePortfolioChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedPortfolios(typeof value === "string" ? value.split(",") : value);
  };

  const resetForm = () => {
    setUserText("");
    setSelectedPortfolios([]);
    setFiles([]);
    setShowResetButton(false);
    setCurrentDate();
  };

  const handleFilesSelected = (newFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileDelete = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  return (
    <Paper style={{ width: "100%", paddingTop: "40px" }}>
      <Helmet title="Quarterly Updates" />

      <Typography
        variant="h3"
        style={{
          textAlign: "center",
          marginBottom: "20px",
          fontWeight: "bold",
        }}
      >
        Portfolio Review Textboxes
      </Typography>
      <Divider my={6} />
      <InfoCard
        purpose="This tool provides the Texboxes related information to be used in reporting (Page 1 and Page 2) for the Front-Office."
        input="Fund underlying portfolios, Reporting Date (Year and Quarter), reports, and additional notes."
        output="You will receive an email with the summarized information as an attached PDF once the extraction process is completed."
      />

      <div
        style={{
          width: "100%",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            width: "50%",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{
                  padding: "10px",
                }}
              >
                Select fund name:
              </Typography>
              <FormControl fullWidth>
                <InputLabel>Funds</InputLabel>
                <Select
                  value={fundID}
                  style={{ maxWidth: "300px" }}
                  onChange={handleFundChange}
                  label="Funds"
                >
                  {fundIDs.map((fund) => (
                    <MenuItem key={fund["Fund_Name"]} value={fund["fund_ID"]}>
                      {fund["Fund_Name"]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{
                  padding: "10px",
                }}
              >
                Select reporting quarter and year:
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Quarter</InputLabel>
                    <Select
                      value={selectedQuarter}
                      onChange={handleQuarterChange}
                      label="Quarter"
                    >
                      {quarters.map((quarter) => (
                        <MenuItem key={quarter.name} value={quarter.value}>
                          {quarter.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Year</InputLabel>
                    <Select
                      value={selectedYear}
                      onChange={handleYearChange}
                      label="Year"
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              fontSize="15px"
              style={{
                padding: "10px",
              }}
            >
              Select portfolios:
            </Typography>
            <FormControl fullWidth>
              <Select
                multiple
                displayEmpty
                value={selectedPortfolios}
                onChange={handlePortfolioChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Select all portfolios</em>;
                  }

                  // Map selected company IDs back to their corresponding company names
                  const selectedNames = portfolioOptions
                    .filter((portfolio: any) =>
                      selected.includes(portfolio.company_ID)
                    )
                    .map((portfolio: any) => portfolio.portfolio_companyName);

                  return selectedNames.join(", ");
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled value="">
                  <em>Select all portfolios</em>
                </MenuItem>
                {portfolioOptions.map((portfolio: any) => (
                  <MenuItem
                    key={portfolio.company_ID}
                    value={portfolio.company_ID} // Now using company_ID as the value
                    style={getStyles(
                      portfolio.portfolio_companyName,
                      selectedPortfolios,
                      theme
                    )}
                  >
                    {portfolio.portfolio_companyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <MultipleFileUpload
              acceptedFileTypes="application/pdf"
              multiple={true}
              files={files}
              onFilesSelected={handleFilesSelected}
              onFileDelete={handleFileDelete}
            />
          </Grid>
          {/* 
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginTop: "50px",
                width: "100%",
                justifyContent: "center",
                border: "2px dashed gray",
                padding: "20px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onDragOver={(event) => event.preventDefault()}
              onDrop={onDrop}
              onClick={() => inputFileRef.current?.click()}
            >
              {files.length > 0
                ? `${files.length} PDFs selected`
                : "Drag & Drop or Click to Select Files"}
              <input
                type="file"
                accept="application/pdf"
                style={{ display: "none" }}
                onChange={onFileChange}
                ref={inputFileRef}
                multiple
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                marginTop: "10px",
              }}
            >
              {files.map((file, index) => (
                <div key={index} style={{ display: "flex" }}>
                  <div
                    style={{
                      justifyContent: "center",
                      padding: "10px",
                      width: "85%",
                      overflow: "hidden",
                      border: "1px solid #e0e0e0",
                      borderRadius: "5px",
                      display: "flex",
                      boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {file.name}
                    </span>
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      width: "5%",
                    }}
                  >
                    <IconButton
                      aria-label="delete"
                      size="medium"
                      onClick={() => handleDeleteFile(index)}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </div>
                </div>
              ))}
            </div>
          </div> */}

          <TextField
            style={{ marginTop: "20px" }}
            multiline
            minRows={4}
            maxRows={6}
            fullWidth
            value={userText}
            onChange={(e) => setUserText(e.target.value)}
            type="Text"
            label="Notes (Optional)"
            variant="outlined"
            placeholder="Add any notes here you wish to include in the report"
          />
          <TextField
            style={{ marginTop: "20px" }}
            fullWidth
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            type="email"
            label="Email Address"
            variant="outlined"
            placeholder="email@example.com"
          />
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <button
              onClick={runscript}
              style={{
                marginTop: "20px",
                backgroundColor: "#007BFF",
                color: "white",
                border: "none",
                padding: "12px 24px",
                borderRadius: "20px",
                fontSize: "16px",
                fontWeight: "500",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s, box-shadow 0.2s",
                cursor: "pointer",
                outline: "none",
                textAlign: "center", // Center the text inside the button
              }}
            >
              Process
            </button>

            {showResetButton && (
              <button
                onClick={resetForm}
                style={{
                  marginTop: "20px",
                  backgroundColor: "#FF0000", // Red background
                  color: "white",
                  border: "none",
                  padding: "12px 24px",
                  borderRadius: "20px",
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  cursor: "pointer",
                  outline: "none",
                  textAlign: "center", // Center the text inside the button
                }}
              >
                Reset
              </button>
            )}
          </div>
        </div>
      </div>
    </Paper>
  );
}

PortfolioReviewTextboxesTool.getLayout = function getLayout(
  page: ReactElement
) {
  return <DashboardLayout>{page}</DashboardLayout>;
};

export default PortfolioReviewTextboxesTool;
